import { ActivatedRoute, Router } from '@angular/router';
import { ChangeDetectionStrategy, Component, computed, inject, model, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { MatIconModule } from '@angular/material/icon';

import { map } from 'rxjs';

import { AuthService } from 'src/app/auth.service';
import { ConfigurationService } from 'src/app/configuration.service';

import { type ApplicationRoute } from '@administration/shared/models/application-route';
import { IBranding } from '@administration/shared/models/application-branding.interface';
import { NavigationAccountComponent } from '@layout/navigation/navigation-account/navigation-account.component';
import { NavigationActionsComponent } from '@layout/navigation/navigation-actions/navigation-actions.component';
import { NavigationItemComponent } from '@layout/navigation/navigation-item/navigation-item.component';
import { NavigationTopComponent } from '@layout/navigation/navigation-top/navigation-top.component';
import { ScrollerComponent } from '@core/scroller/scroller.component';

@Component({
  standalone: true,
  selector: 'tess-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  imports: [
    MatIconModule,
    ScrollerComponent,
    NavigationTopComponent,
    NavigationAccountComponent,
    NavigationActionsComponent,
    NavigationItemComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(window:resize)': 'onResize()',
  },
})
export class NavigationComponent {
  closed = model(false);
  ended = signal(false);

  #overlayBreakpoint = parseInt(getComputedStyle(document.body).getPropertyValue('--sidebar-overlay-breakpoint'));

  closePopover = signal<number | undefined>(undefined);

  readonly #authService = inject(AuthService);
  readonly #configurationService = inject(ConfigurationService);
  readonly authenticatedUserSignal = this.#authService.authenticatedUserSignal;
  readonly #router = inject(Router);
  readonly activatedRoute = inject(ActivatedRoute);

  readonly documentation = { Icon: 'documentation', RouteDefinition: { title: 'Documentation' } };

  activeRoute = toSignal(this.#router.events.pipe(map(event => event['url'])), { initialValue: undefined });

  items = computed(() => {
    const activeRoute = this.activeRoute() || this.#router.url;
    const menu = this.authenticatedUserSignal().data?.Menu.map((item: ApplicationRoute) => {
      item.selected = activeRoute === item.RouteDefinition.url;
      item.open = activeRoute === item.RouteDefinition.url;

      // Submenu items.
      if (item.SubItems) {
        item.SubItems = item.SubItems.map((subMenuItem: ApplicationRoute) => {
          if (activeRoute === subMenuItem.RouteDefinition.url) {
            subMenuItem.selected = true;
            item.selected = true;
            item.open = true;
          } else {
            subMenuItem.selected = false;
          }

          return subMenuItem;
        });
      }

      return item;
    });

    return menu;
  });

  branding: IBranding;
  #isOverlay = false;

  onResize = () => {
    this.#checkNavState();
  };

  ngOnInit() {
    this.branding = this.#configurationService.settings.Branding;
    this.#checkNavState();
  }

  toggle(state?: boolean): void {
    this.closed.set(state ?? !this.closed());
    this.ended.set(false);
    setTimeout(() => this.ended.set(true), 500);
  }

  #checkNavState() {
    if (window.innerWidth <= this.#overlayBreakpoint && !this.#isOverlay) {
      this.#isOverlay = true;
      this.closed.set(true);
      this.ended.set(true);
    }

    if (window.innerWidth > this.#overlayBreakpoint && this.#isOverlay) {
      this.#isOverlay = false;
      this.closed.set(false);
      this.ended.set(false);
    }
  }
}
