<div class="navigation-item" [class.navigation-item--closed]="closed()" [class.navigation-item--active]="selected()">
  <a
    role="button"
    tabindex="0"
    (keydown.enter)="navigate(item())"
    (click)="navigate(item())"
    (navigate)="navigate($event)"
    [tessNavigationPopover]="item()"
    [isMenuClosed]="closed() && ended()"
    [closePopover]="closePopover()"
    class="navigation-item__content menu">
    <div class="menu__left">
      <mat-icon [svgIcon]="item().Icon" class="navigation-item__icon icon-tess-white-stroke tess-icon-md" />
    </div>

    <div class="menu__right">
      <div class="navigation-item__title">
        {{ item().RouteDefinition.title }}
        @if (subItems()) {
          <mat-icon svgIcon="arrow-right" class="navigation-item__icon navigation-item__arrow icon-tess-white-stroke tess-icon-xxs" />
        }
      </div>
    </div>
  </a>

  @if (subItems()) {
    <div class="navigation-item__sub" [class.navigation-item__sub--open]="open() || subSelected() || selected()">
      @for (subItem of subItems(); track subItem.Id) {
        <a
          role="button"
          tabindex="0"
          (keydown.enter)="navigate(subItem)"
          (click)="navigate(subItem)"
          class="navigation-item__content navigation-item--sub menu"
          [class.navigation-item--sub--active]="subItem.selected">
          <div class="menu__left navigation-item__left">
            <mat-icon svgIcon="circle" class="navigation-item__icon tess-icon-xs" />
          </div>
          <div class="menu__right">
            <div class="navigation-item__title">
              {{ subItem.RouteDefinition.title }}
            </div>
          </div>
        </a>
      }
    </div>
  }
</div>
