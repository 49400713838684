import { ChangeDetectionStrategy, Component, input, output } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

import { type IBranding } from '@administration/shared/models/application-branding.interface';

@Component({
  selector: 'tess-navigation-top',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './navigation-top.component.html',
  styleUrl: './navigation-top.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationTopComponent {
  branding = input.required<IBranding>();
  toggle = output<void>();
  closed = input(false);
}
