<ngx-loading-bar [includeSpinner]="false" color="#6989a9"></ngx-loading-bar>

<div class="layout">
  @if (showMenuSignal()) {
    <tess-navigation [(closed)]="closedSidebar" />
  }

  <div class="main-layout" [class.closed-sidebar]="closedSidebar()">
    @if (showMenuSignal()) {
      <tess-toolbar></tess-toolbar>
    }

    <!-- I indicate that a route is being loaded asynchronously. -->
    @if (activeOutletSignal(); as activeOutlet) {
      <main [class.lazy-load-route-opacity]="showLazyLoadRouteLoadIndicator()">
        <div [hidden]="activeOutlet !== 'primary'" class="height-1-1">
          <router-outlet (activate)="activateRouteOutlet()" (deactivate)="deactivateRouteOutlet()" />
        </div>

        <div [hidden]="activeOutlet !== 'detail'" class="height-1-1">
          <router-outlet
            name="detail"
            (activate)="activateRouteOutlet()"
            (deactivate)="deactivateRouteOutlet()" />
        </div>

        <div [hidden]="activeOutlet !== 'subdetail'" class="height-1-1">
          <router-outlet
            name="subdetail"
            (activate)="activateRouteOutlet()"
            (deactivate)="deactivateRouteOutlet()" />
        </div>

        <router-outlet name="yyy" (activate)="activateRouteOutlet()" (deactivate)="deactivateRouteOutlet()" />
      </main>
    }
  </div>
</div>

<!-- <tour-step-template></tour-step-template> -->
