@if (authenticatedUserSignal(); as authenticatedUser) {
  @let closedNav = closed();
  @let endedClose = ended();

  <div class="navigation" [class.closed]="closedNav">
    <tess-navigation-top [branding]="branding" (toggle)="toggle()" [closed]="closedNav" />
    <div class="navigation__content">
      <div class="navigation-border-top navigation-border-bottom">
        <tess-navigation-account [user]="authenticatedUser.data" />
        <tess-navigation-actions />
      </div>

      <tess-scroller>
        @for (item of items(); track item.Id) {
          <tess-navigation-item
            [item]="item"
            [subItems]="item.SubItems"
            [selected]="item.selected"
            [closed]="closedNav"
            [ended]="endedClose" />
        }
      </tess-scroller>

      <div class="navigation-border-top">
        <tess-navigation-item [item]="documentation" [closed]="closedNav && endedClose" />
      </div>
    </div>
  </div>

  <div class="navigation__backdrop" tabindex="0" (keydown.enter)="toggle(true)" (click)="toggle(true)"></div>
}
