import { afterNextRender, AfterViewInit, Component, inject, OnInit, signal, viewChild } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  RouteConfigLoadEnd,
  RouteConfigLoadStart,
  Router,
  RouterOutlet,
} from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { catchError, exhaustMap, filter, fromEvent, map, of, take, tap } from 'rxjs';

import { register } from 'swiper/element/bundle';
import { TourStepTemplateComponent } from 'ngx-ui-tour-md-menu';

import { LoadingBarModule } from '@ngx-loading-bar/core';
import { TranslateService } from '@ngx-translate/core';

import { ApplicationConfiguration } from '@administration/shared/models/application-configuration.interface';
import { ArrayUtilities } from '@core/utilities/array-utilities';
import { CommunicationService } from '@core/services/communication/communication.service';
import { DocumentUtilities } from '@core/utilities/document';
import { NavigationComponent } from '@layout/navigation/navigation/navigation.component';
import { ResizeObserverService } from '@core/observers/resize-observer/services/resize-observer.service';
import { RouterUtilities } from '@core/utilities/router-utilities';
import { TessSignalBusService } from '@core/services/tess-signal-bus.service';
import { ToolbarComponent } from '@layout/toolbar/toolbar/toolbar.component';

import { AuthService } from './auth.service';
import { NotificationsService } from './notifications.service';

@Component({
  standalone: true,
  selector: 'tess-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [ResizeObserverService],
  imports: [AsyncPipe, RouterOutlet, LoadingBarModule, NavigationComponent, ToolbarComponent],
})
export class AppComponent implements OnInit, AfterViewInit {
  tourStepTemplateComponent = viewChild<TourStepTemplateComponent>(TourStepTemplateComponent);

  readonly #communicationService = inject(CommunicationService);
  readonly #resizeObserverService = inject(ResizeObserverService);
  readonly #router = inject(Router);
  readonly #translate = inject(TranslateService);
  readonly #tessSignalBusService = inject(TessSignalBusService);
  readonly #authService = inject(AuthService);
  readonly #notificationsService = inject(NotificationsService);

  closedSidebar = signal(false);
  activeOutletSignal = signal<'primary' | 'detail' | 'subdetail' | 'yyy'>('primary');
  showMenuSignal = signal(false);
  showLazyLoadRouteLoadIndicator = signal(0);

  #currentRoute = '';
  #currentRouteOutlets: string[] = [];

  status = '';

  loading = this.#router.events.pipe(
    filter(e =>
      [
        // Only react to the following events.
        NavigationStart,
        NavigationEnd,
        NavigationError,
        NavigationCancel,
      ].some(constructor => e instanceof constructor),
    ),
    map(e => e instanceof NavigationStart),
    // this line saves us to have to manually subscribe to the stream
    tap(e => {
      this.status = e ? 'navigating' : 'done';
    }),
  );

  constructor() {
    afterNextRender(() => register());

    this.#resizeObserverService.subscribe((entries: ResizeObserverEntry[]): void => {
      // Emit only the last entry.
      this.#tessSignalBusService.set(ApplicationConfiguration.CACHE_KEY_RESIZE_OBSERVER, entries?.pop());
    });

    this.#routerSubscriptionSetup();

    // todo: for debug
    fromEvent(document, 'keydown')
      .pipe(
        filter(event => {
          return event instanceof KeyboardEvent && event.shiftKey && event.code == 'Digit5'; // shift + 5
        }),
        map(() => this.#authService.getAuthenticatedUser()?.Id),
        filter(userId => userId != null),
        exhaustMap(userId => {
          const message = prompt('Test push notification');

          if (!message?.trim().length) {
            return of(undefined);
          }

          return this.#notificationsService.sendPushNotification([userId], 'Test notification', message).pipe(
            take(1),
            catchError(() => of(undefined)),
          );
        }),
        takeUntilDestroyed(),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.#translate.addLangs(['en', 'fr']);
    this.#translate.setDefaultLang('en');
    const browserLang = this.#translate.getBrowserLang();
    this.#translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');

    // Trigger initial navigation since we are bootstrapping the application manually.
    this.#router.initialNavigation();
  }

  ngAfterViewInit(): void {
    this.#communicationService.tourStepTemplateComponent = this.tourStepTemplateComponent();
  }

  activateRouteOutlet() {
    this.#setActiveOutlet();
  }

  deactivateRouteOutlet() {
    this.#setActiveOutlet();
  }

  #setActiveOutlet(): void {
    // Retrieve all active outlet.
    const outlets = RouterUtilities.getActiveOutlets(this.#router);

    if (outlets.get('yyy')) {
      this.activeOutletSignal.set('yyy');
    } else if (outlets.get('subdetail')) {
      this.activeOutletSignal.set('subdetail');
    } else if (outlets.get('detail')) {
      this.activeOutletSignal.set('detail');
    } else {
      this.activeOutletSignal.set('primary');
    }
  }

  #routerSubscriptionSetup() {
    // As the router loads modules asynchronously (via loadChildren), we're going to
    // keep track of how many asynchronous requests are currently active. If there is
    // at least one pending load request, we'll show the indicator.
    let asyncLoadCount = 0;

    // Subscribe to the router events.
    this.#router.events
      //.pipe(filter((event: Event | RouterEvent): event is RouterEvent => event instanceof RouterEvent))
      .subscribe((event: any) => {
        // The Router emits special events for "loadChildren" configuration loading. We
        // just need to listen for the Start and End events in order to determine if we
        // have any pending configuration requests.
        if (event instanceof RouteConfigLoadStart) {
          asyncLoadCount++;
        } else if (event instanceof RouteConfigLoadEnd) {
          asyncLoadCount--;
        } else if (event instanceof NavigationStart) {
          asyncLoadCount++;
        } else if (event instanceof NavigationEnd) {
          asyncLoadCount = 0;

          const currentRouteOutlets = [...RouterUtilities.getActiveOutlets(this.#router).keys()];
          // Compare the outlets.
          const equality = ArrayUtilities.compare(currentRouteOutlets, this.#currentRouteOutlets);
          // Trigger a scroll event in the event the outlets match entirely, without taking into account the primary outlet.
          if (currentRouteOutlets.length > 1 && equality) {
            DocumentUtilities.scrollToTop('tess-page-content tess-scrollbar');
          }

          this.#currentRouteOutlets = currentRouteOutlets;
          // Keep track of the current route.
          this.#currentRoute = event.url;
          if (this.#currentRoute.indexOf('authentication') > -1) {
            this.showMenuSignal.set(false);
          } else {
            this.showMenuSignal.set(true);
          }
        } else if (event instanceof NavigationCancel) {
          asyncLoadCount = 0;
        } else if (event instanceof NavigationError) {
          // Present error to user
          asyncLoadCount = 0;
        }

        // If there is at least one pending asynchronous config load request,
        // then let's show the loading indicator.
        // --
        // CAUTION: The CSS includes a small 100ms delay such that the loading
        // indicator won't be seen by users with sufficiently fast connections.
        this.showLazyLoadRouteLoadIndicator.set(asyncLoadCount);
      });
  }
}
