import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslateService } from '@ngx-translate/core';

import { AuthService } from 'src/app/auth.service';
import { ConfigurationService } from 'src/app/configuration.service';

import { AvatarComponent } from '@core/images/avatar/avatar.component';
import { HasFeatureDirective } from '@core/has-feature.directive';
import { IBranding } from '@administration/shared/models/application-branding.interface';
import { ToolbarHistoryComponent } from '@layout/toolbar/toolbar-history/toolbar-history.component';
import { ToolbarNotificationsComponent } from '@layout/toolbar/toolbar-notifications/toolbar-notifications.component';
import { ToolbarSearchComponent } from '@layout/toolbar/toolbar-search/toolbar-search.component';
import { ToolbarShortcutsComponent } from '@layout/toolbar/toolbar-shortcuts/toolbar-shortcuts.component';
import { TooltipDirective } from '@core/tooltip/tooltip.directive';

@Component({
  standalone: true,
  selector: 'tess-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    MatButtonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatToolbarModule,
    AvatarComponent,
    HasFeatureDirective,
    ToolbarSearchComponent,
    ToolbarHistoryComponent,
    ToolbarNotificationsComponent,
    ToolbarShortcutsComponent,
    TooltipDirective,
  ],
})
export class ToolbarComponent implements OnInit {
  readonly #authService = inject(AuthService);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #configurationService = inject(ConfigurationService);
  readonly #destroyRef = inject(DestroyRef);
  readonly #router = inject(Router);
  readonly #translate = inject(TranslateService);

  readonly authenticatedUserSignal = this.#authService.authenticatedUserSignal;

  branding: IBranding;
  currentLanguage: string;
  languages: string[];

  ngOnInit() {
    this.languages = this.#translate.getLangs();
    this.currentLanguage = this.#translate.currentLang;
    this.branding = this.#configurationService.settings.Branding;

    this.#translate.onLangChange.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(item => {
      this.currentLanguage = item.lang;
      this.#changeDetectorRef.detectChanges();
    });
  }

  getLanguage(language: string): string {
    return language === 'en' ? 'US' : language.toUpperCase();
  }

  logOut(): void {
    this.#authService.logOut();
  }

  navigateToMyCompany(): void {
    this.#router.navigate([
      '/companies',
      { outlets: { detail: ['company', this.#authService.getAuthenticatedUser().Company.Id, 'information'] } },
    ]);
  }

  navigateToMyProfile(): void {
    this.#router.navigate([
      '/users',
      { outlets: { detail: ['user', this.#authService.getAuthenticatedUser().Id, 'information'] } },
    ]);
  }

  onLanguageChange(language: string) {
    this.#translate.use(language);
  }
}
