import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';

import { debounceTime, distinctUntilChanged, filter, fromEvent, tap } from 'rxjs';

import { ToolbarSearchMock } from '@layout/toolbar/toolbar-search/toolbar-search-mock';

export class ResultData {
  title: string;
  entries = new Array<ResultEntry>();
}

class ResultEntry {
  link: string;
  title: string;
  description: string;
}

@Component({
  standalone: true,
  selector: 'tess-toolbar-search',
  templateUrl: './toolbar-search.component.html',
  styleUrls: ['./toolbar-search.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatInputModule],
})
export class ToolbarSearchComponent implements AfterViewInit, OnDestroy {
  @ViewChild('search_input')
  private input: ElementRef<HTMLInputElement>;

  @ViewChild('scroll_anchor')
  private anchor: ElementRef<HTMLElement>;

  @ViewChild('scroll_container')
  private scrollContainer: ElementRef<HTMLElement>;

  readonly #breakpointObserver = inject(BreakpointObserver);
  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #destroyRef = inject(DestroyRef);

  activeSearch: boolean;
  totalFilteredData: ResultData[] = [];
  paginatedResultData: ResultData[] = [];
  loadedDataIndex = 0;

  #observer: IntersectionObserver;

  ngAfterViewInit(): void {
    this.#listenToSearch();
    this.#listenToInfiniteScroll();
  }

  ngOnDestroy(): void {
    this.#observer.disconnect();
  }

  openSearch(): void {
    this.activeSearch = true;
    this.input.nativeElement.focus();
  }

  closeSearch(): void {
    this.activeSearch = false;
  }

  openSearchOnDesktop(): void {
    if (this.#breakpointObserver.isMatched('min-width: 1033px')) {
      this.openSearch();
    }
  }

  clearSearchBox(): void {
    if (this.#breakpointObserver.isMatched('(max-width: 1032px)')) {
      this.closeSearch();
      this.#changeDetectorRef.detectChanges();
      return;
    }

    this.totalFilteredData = [];
    this.paginatedResultData = [];
    this.input.nativeElement.value = '';
  }

  navigateToPage(link: string): void {
    // this.$state.go(link);
    console.log(link);
    this.closeSearch();
  }

  #listenToSearch(): void {
    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        debounceTime(200),
        distinctUntilChanged(),
        filter((value: any) => {
          // no results for under 2 characters typed
          if (value.target.value.length <= 1) {
            this.paginatedResultData = new Array<ResultData>();
            this.totalFilteredData = new Array<ResultData>();
            this.#changeDetectorRef.detectChanges();
            return false;
          }
          return true;
        }),
        tap(() => this.#searchText()),
      )
      .subscribe();
  }

  #searchText(): void {
    this.scrollContainer.nativeElement.scrollTop = 0;
    this.paginatedResultData = new Array<ResultData>();
    this.totalFilteredData = new Array<ResultData>();
    this.#mapSearchResults();
    this.#mapPaginationResults(0);
    this.#mapPaginationResults(3);
    this.loadedDataIndex = 3;
    this.#changeDetectorRef.detectChanges();
  }

  #mapSearchResults(): void {
    const response = ToolbarSearchMock.getResults();
    response.forEach(resultData => {
      resultData.entries.forEach(entry => {
        entry.title = `${entry.title} - ${entry.description}`.slice(0, 120);
        if (entry.title.length === 120) {
          entry.title += '...';
        }
      });
    });
    this.totalFilteredData = response;
    this.#changeDetectorRef.detectChanges();
  }

  #listenToInfiniteScroll(): void {
    this.#observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        this.#loadMoreResults(entries);
        this.#changeDetectorRef.detectChanges();
      },
      { root: this.scrollContainer.nativeElement, rootMargin: '150px' },
    );

    this.#observer.observe(this.anchor.nativeElement as Element);
  }

  #loadMoreResults(entries): void {
    entries.forEach((entry: IntersectionObserverEntry) => {
      if (entry.isIntersecting && entry.target === this.anchor.nativeElement) {
        this.loadedDataIndex += 3;
        this.#mapPaginationResults(this.loadedDataIndex);
      }
    });
  }

  #mapPaginationResults(startIdx: number): void {
    const stopIdx = startIdx + 3 > this.totalFilteredData.length ? this.totalFilteredData.length : startIdx + 3;

    for (let i = startIdx; i < stopIdx; i++) {
      this.paginatedResultData.push(this.totalFilteredData[i]);
    }

    if (this.totalFilteredData.length === 1 && this.paginatedResultData.length === 0) {
      this.paginatedResultData.push(this.totalFilteredData[0]);
    }
  }
}
