@if (user(); as user) {
  <div class="menu">
    <div class="menu__left">
      <tess-avatar [src]="user.Contact?.PhotoURL" [initials]="user.Contact?.Initials" [title]="user.Contact?.FullName" size="sm" />
    </div>

    <div class="menu__right">
      <div class="navigation-account">
        <div>
          {{ user.Contact?.FirstName }}
          {{ user.Contact?.LastName }}
        </div>
        <div>{{ user.Company?.Name }}</div>
      </div>
    </div>
  </div>
}
