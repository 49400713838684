import { ApplicationRef, enableProdMode } from '@angular/core';
import { createApplication } from '@angular/platform-browser';
import { createCustomElement } from '@angular/elements';

import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';
import { InjectorLocatorService } from './app/injector-locator.service';

if (environment.production) {
  enableProdMode();
}

createApplication(appConfig).then(async (applicationRef: ApplicationRef) => {
  // Create the element.
  const ElementConstructor = createCustomElement(AppComponent, {
    injector: applicationRef.injector,
  });
  customElements.define('tess-root', ElementConstructor);

  // Add the element to the DOM.
  const componentElement = document.createElement('tess-root');
  document.body.appendChild(componentElement);

  // Set the injector.
  // TODO: this is not elegant and should be removed.
  InjectorLocatorService.injector = applicationRef.injector;

  // Retrieve and swap the two text components.
  const tessLoaderTextInitial = document.querySelector('.tess-loader-text-initial');
  const tessLoaderTextFinal = document.querySelector('.tess-loader-text-final');
  if (tessLoaderTextInitial !== null) {
    tessLoaderTextInitial.className = 'tess-loader-text-initial tess-loader-text-invisible';
  }

  if (tessLoaderTextFinal !== null) {
    tessLoaderTextFinal.className = 'tess-loader-text-final tess-loader-text-visible';
  }

  // Retrieve and update the progress bar.
  const tessLoaderProgressBar = document.querySelector('.tess-loader-progress-bar-fill');
  tessLoaderProgressBar?.classList.add('tess-loader-progress-bar-fill-final');
  // Add a delay after the text and progress bar have been changed.
  // TODO: change the delay to 1000 before production.
  const delay = 100;
  await new Promise(resolve => setTimeout(resolve, delay));

  // Remove the loader.
  const tessLoaderElement = document.querySelector('#tess-loader');
  tessLoaderElement?.remove();
});
