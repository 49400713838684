<button type="button" mat-icon-button aria-label="Quick Links" class="shortcuts-trigger" [matMenuTriggerFor]="menu">
  <mat-icon class="plus-icon tess-icon-sm" svgIcon="mdi:plus"></mat-icon>
</button>
<mat-menu #menu="matMenu" class="toolbar-menu">
  <div class="quick-links">
    <span>Quick links</span>
  </div>
  @for (option of options; track option.link) {
    <div (click)="navigate(option.link)" class="quick-link-option">
      <mat-icon [svgIcon]="option.icon" class="margin-right-8"></mat-icon>
      <div>
        <div class="option-title">{{ option.title }}</div>
        <div>{{ option.description }}</div>
      </div>
    </div>
  }
</mat-menu>
