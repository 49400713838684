import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  standalone: true,
  selector: 'tess-toolbar-shortcuts',
  templateUrl: './toolbar-shortcuts.component.html',
  styleUrls: ['./toolbar-shortcuts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatMenuModule],
})
export class ToolbarShortcutsComponent {
  readonly #router = inject(Router);

  readonly options = [
    {
      title: 'Add Client/ Lead',
      description: 'Shortcut to adding a new client or lead',
      icon: 'account-group',
      link: '/clients/add/information',
    },
    {
      title: 'Add Trip',
      description: 'Shortcut to adding a standard trip',
      icon: 'trip-management',
      link: '/trips/add/information',
    },
    {
      title: 'Add Helpdesk Ticket',
      description: 'Shortcut to adding a helpdesk ticket',
      icon: 'helpdesk',
      link: '/helpdesk/add/general',
    },
  ];

  public navigate(link: string): void {
    this.#router.navigate([link]);
  }
}
