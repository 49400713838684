import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  effect,
  ElementRef,
  inject,
  Injector,
  input,
  NgZone,
  OnInit,
  output,
  signal,
  untracked,
} from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { FlexibleConnectedPositionStrategy, Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  fromEvent,
  map,
  merge,
  Observable,
  Subscription,
} from 'rxjs';

import { NavigationPopoverComponent } from '@layout/navigation/navigation-popover/navigation-popover.component';

@Directive({
  standalone: true,
  selector: '[tessNavigationPopover]',
  host: {
    '[class]': 'hostClassName()',
  },
})
export class NavigationPopoverDirective implements OnInit {
  protected readonly item = input.required<any>({ alias: 'tessNavigationPopover' });
  protected readonly isMenuClosed = input.required<boolean>();
  protected readonly closePopover = input.required<number | undefined>();

  readonly navigate = output<any>();

  readonly #changeDetectorRef = inject(ChangeDetectorRef);
  readonly #destroyRef = inject(DestroyRef);
  readonly #injector = inject(Injector);
  readonly #elementRef = inject(ElementRef);
  readonly #ngZone = inject(NgZone);
  readonly #overlay = inject(Overlay);

  protected readonly hostClassName = signal<string | undefined>(undefined);

  readonly #isOverlayOpen = signal(false);
  #overlayRef!: OverlayRef | null;
  readonly #position = signal<string | null>(null);

  readonly #documentMouseMove$ = fromEvent(document, 'mousemove');
  #hostMouseEnter$!: Observable<Event>;
  #hostMouseLeave$!: Observable<Event>;
  #hostMouseEvents$!: Observable<boolean>;

  #subscription$!: Subscription;

  constructor() {
    effect(() => {
      const close = this.closePopover();
      const closed = this.isMenuClosed();
      if (close) {
        untracked(() => this.#isOverlayOpen.set(false));
        if (this.#overlayRef) {
          this.#overlayRef.dispose();
          this.#overlayRef = null;
        }

        this.#subscription$?.unsubscribe();
        if (closed) {
          this.#hostMouseEventsSetup();
        }
      }
    });

    effect(() => {
      const closed = this.isMenuClosed();
      // Ensure all overlays are closed because one could be hovered and the menu could be opened
      // using touch gestures.
      untracked(() => this.#changeState(false));
      // Remove mouse subscription events for the overlay.
      this.#subscription$?.unsubscribe();
      // If the menu was collapsed, reinitialize the host events.
      if (closed) {
        this.#hostMouseEventsSetup();
      }
    });
  }

  ngOnInit(): void {
    // Initial mouse enter and mouse leave events.
    this.#ngZone.runOutsideAngular(() => {
      this.#hostMouseEnter$ = fromEvent(this.#elementRef.nativeElement, 'mouseenter');
      this.#hostMouseLeave$ = fromEvent(this.#elementRef.nativeElement, 'mouseleave');

      this.#hostMouseEvents$ = merge(
        this.#hostMouseEnter$.pipe(map(() => true)),
        this.#hostMouseLeave$.pipe(map(() => false)),
      );
    });
  }

  #changeState(newState: boolean): void {
    // The new state is the same as the old, we do nothing.
    if (this.#isOverlayOpen() === newState) {
      return;
    }

    this.hostClassName.set(newState ? 'popover' : undefined);
    this.#changeDetectorRef.markForCheck();

    this.#isOverlayOpen.set(newState);

    if (this.#overlayRef) {
      this.#overlayRef.dispose();
      this.#overlayRef = null;
    }

    if (newState) {
      this.#openOverlay({ origin: this.#elementRef.nativeElement });
    }
  }

  // Initial event setup.
  #hostMouseEventsSetup(): void {
    this.#subscription$ = this.#hostMouseEvents$
      .pipe(debounceTime(10), distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef))
      .subscribe(value => {
        // Run the change state inside ngZone.
        this.#ngZone.runGuarded(() => {
          this.#changeState(value);
        });

        // Tear down the initial events if the overlay is open, and setup the overlay events.
        if (value) {
          this.#subscription$.unsubscribe();
          this.#overlayMouseEventsSetup();
        }
      });
  }

  // Event setup with the overlay open.
  #overlayMouseEventsSetup(): void {
    this.#ngZone.runOutsideAngular(() => {
      const overlayMouseEnter$ = fromEvent(this.#overlayRef!.overlayElement, 'mouseenter');
      const overlayMouseLeave$ = fromEvent(this.#overlayRef!.overlayElement, 'mouseleave');

      const mergedEvents$ = merge(
        this.#hostMouseEvents$,
        overlayMouseEnter$.pipe(map(() => true)),
        overlayMouseLeave$.pipe(map(() => false)),
      );

      this.#subscription$ = combineLatest([this.#documentMouseMove$, mergedEvents$])
        .pipe(debounceTime(10), distinctUntilChanged(), takeUntilDestroyed(this.#destroyRef))
        .subscribe(([_, value]) => {
          // Teardown the overlay events if the overlay is being closed.
          if (!value) {
            this.#subscription$.unsubscribe();
            this.#hostMouseEventsSetup();
          }

          // Run the change state inside ngZone.
          this.#ngZone.runGuarded(() => {
            this.#changeState(value);
          });
        });
    });
  }

  #openOverlay({
    origin,
    position,
    width,
    height,
    hasBackdrop,
  }: {
    origin: any;
    position?: any;
    width?: any;
    height?: any;
    hasBackdrop?: any;
  }): void {
    // Otherwise create a new popup.
    const overlayConfig = this.#getOverlayConfig({
      origin,
      position,
      width,
      height,
      hasBackdrop,
    });

    this.#overlayRef = this.#overlay.create(overlayConfig);
    const componentRef = this.#overlayRef.attach(new ComponentPortal(NavigationPopoverComponent, null, this.#injector));
    componentRef.setInput('item', this.item());

    componentRef.instance.navigate.subscribe((item: any) => {
      this.#changeState(false);
      this.navigate.emit(item);
    });

    if (overlayConfig.positionStrategy instanceof FlexibleConnectedPositionStrategy) {
      // Subscribe to the position changes observable.
      overlayConfig.positionStrategy.positionChanges.pipe(takeUntilDestroyed(this.#destroyRef)).subscribe(item => {
        const positionStrategyClass = `${item.connectionPair.originX}-${item.connectionPair.originY}-${item.connectionPair.overlayX}-${item.connectionPair.overlayY}`;
        this.#position.set(positionStrategyClass);
        // Set the component input.
        componentRef.setInput('position', this.#position());
      });
    }
  }

  #getOverlayConfig({
    origin,
    width,
    height,
    hasBackdrop,
  }: {
    origin: any;
    position?: any;
    width?: any;
    height?: any;
    hasBackdrop?: any;
  }): OverlayConfig {
    // // Validate.
    // if (origin && position instanceof PopoverGlobalPosition) {
    //   throw new Error('a global position cannot have an origin');
    // }

    // if (!origin && position instanceof PopoverFlexiblePosition) {
    //   throw new Error('a flexible position must have an origin');
    // }

    // Return the overlay config.
    return new OverlayConfig({
      hasBackdrop: hasBackdrop,
      width,
      height,
      backdropClass: '', //: hasBackdrop ? 'tess-popover-backdrop' : '',
      positionStrategy: this.#getOverlayFlexiblePosition(origin),
      // position instanceof PopoverFlexiblePosition
      //   ? this.getOverlayFlexiblePosition(origin, position)
      //   : this.getOverlayGlobalPosition(position),
      scrollStrategy: this.#overlay.scrollStrategies.reposition(),
    });
  }

  #getOverlayFlexiblePosition(origin: HTMLElement): FlexibleConnectedPositionStrategy {
    const positionStrategy = this.#overlay
      .position()
      .flexibleConnectedTo(origin)
      // .withPositions(PopoverFlexiblePositionStrategy.get(position.strategy, position.offset))
      .withPositions([
        {
          originX: 'end',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
          offsetX: -4,
        },
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'bottom',
          offsetX: -4,
        },
      ])
      .withFlexibleDimensions(false)
      .withPush(false);

    return positionStrategy;
  }
}
