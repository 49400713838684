import { Directive, inject, input, Input, TemplateRef, ViewContainerRef } from '@angular/core';

import { AuthService } from '../auth.service';

@Directive({
  standalone: true,
  selector: '[tessHasFeature]',
})
export class HasFeatureDirective {
  // TODO
  accessType = input.required<string>();

  readonly #authService = inject(AuthService);
  readonly #templateRef = inject(TemplateRef<any>);
  readonly #viewContainer = inject(ViewContainerRef);

  @Input()
  set tessHasFeature(feature: string) {
    const applicationFeature = this.#authService
      .getAuthenticatedUser()
      .ApplicationFeatures?.find(item => item.ApplicationFeature.Name === feature);

    // Clear.
    this.#viewContainer.clear();
    if (applicationFeature?.ApplicationSecurityLevel?.Name === this.accessType()) {
      this.#viewContainer.createEmbeddedView(this.#templateRef);
    }

    if (
      (applicationFeature && applicationFeature.ApplicationSecurityLevel.Name !== this.accessType()) ||
      (!applicationFeature && this.accessType() !== 'None')
    ) {
      // Do nothing.
    } else {
      this.#viewContainer.createEmbeddedView(this.#templateRef);
    }
  }
}
