import { ChangeDetectionStrategy, Component, computed, inject, input, signal } from '@angular/core';
import { Router } from '@angular/router';

import { MatIconModule } from '@angular/material/icon';

import { type ApplicationRoute } from '@administration/shared/models/application-route';
import { NavigationPopoverDirective } from '@layout/navigation/navigation-popover-directive/navigation-popover.directive';

@Component({
  selector: 'tess-navigation-item',
  standalone: true,
  imports: [MatIconModule, NavigationPopoverDirective],
  templateUrl: './navigation-item.component.html',
  styleUrl: './navigation-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[style.--navigation-item-max-height]': 'subItemsHeight()',
  },
})
export class NavigationItemComponent {
  protected readonly item = input.required<ApplicationRoute>();
  protected readonly subItems = input<ApplicationRoute[]>();
  protected readonly selected = input(false);
  protected readonly closed = input(false);
  protected readonly ended = input(false);
  protected readonly open = signal(false);

  readonly #router = inject(Router);

  protected readonly closePopover = signal<number | undefined>(undefined);
  protected readonly subSelected = computed(() => !!this.subItems().find(item => item.selected));
  protected readonly subItemsHeight = computed(() => `${(this.subItems()?.length || 0) * 42}px`);

  navigate(item: ApplicationRoute): void {
    if (item.SubItems) {
      if (!this.closed()) {
        this.open.set(!this.open());
      }

      return;
    }

    this.closePopover.set(new Date().getTime());
    // https://github.com/angular/angular/pull/40272
    // RouterUtilities.closeOutlet('detail').then(_ => this._router.navigate([item.RouteDefinition.url]));
    // TO DO: do not use router.navigate above because outlets are not cleared.
    this.#router.navigateByUrl(item.RouteDefinition.url);
  }
}
