import { ChangeDetectionStrategy, Component } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

@Component({
  selector: 'tess-navigation-actions',
  standalone: true,
  imports: [MatIconModule],
  templateUrl: './navigation-actions.component.html',
  styleUrl: './navigation-actions.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationActionsComponent {}
