<button type="button" mat-icon-button class="history-trigger" [matMenuTriggerFor]="menu">
  <mat-icon class="history-icon" svgIcon="history"></mat-icon>
</button>
<mat-menu #menu="matMenu" class="toolbar-menu">
  @for (option of options; track option.title) {
    <div (click)="navigate(option.routeName)" (keypress.enter)="navigate(option.routeName)" tabindex="0" class="link-option">
      <div class="option-title">{{ option.title }}</div>
    </div>
  }
</mat-menu>
