@if (item(); as item) {
  <a
    role="button"
    tabindex="0"
    (keydown.enter)="navigate.emit(item)"
    (click)="navigate.emit(item)"
    class="main-menu-item"
    [class.active]="item.selected"
    [class.disabled]="item.SubItems">
    <div class="main-menu-container">
      <div class="main-menu-title">
        {{ item.RouteDefinition.title }}
      </div>
    </div>
  </a>

  @if (item.SubItems) {
    <div class="sub-menu">
      @for (subItem of item.SubItems; track subItem.Id) {
        <a
          role="button"
          tabindex="0"
          (keydown.enter)="navigate.emit(subItem)"
          (click)="navigate.emit(subItem)"
          class="sub-menu-item"
          [class.active]="subItem.selected">
          <div class="sub-menu-container">
            <div class="main-menu-title">
              {{ subItem.RouteDefinition.title }}
            </div>
          </div>
        </a>
      }
    </div>
  }
}
