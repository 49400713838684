import { DomSanitizer } from '@angular/platform-browser';
import { Injectable } from '@angular/core';

import { MatIconRegistry } from '@angular/material/icon';

@Injectable({ providedIn: 'root' })
export class IconRepositoryService {
  readonly #customIcons: [string, string][] = [
    ['tess-logo', './assets/svg/tess-logo.svg'],
    ['tess-logo-icon', './assets/svg/tess-logo-icon.svg'],

    ['activity', './assets/svg/source/generic/activity.svg'],
    ['alert', './assets/svg/source/mdi/alert.svg'],
    ['alert-outline', './assets/svg/source/mdi/alert-outline.svg'],
    ['alert-circle-outline', './assets/svg/source/mdi/alert-circle-outline.svg'],
    ['account-arrow-left-outline', './assets/svg/source/mdi/account-arrow-left-outline.svg'],
    ['account-check-outline', './assets/svg/source/mdi/account-check-outline.svg'],
    ['account-clock-outline', './assets/svg/source/mdi/account-clock-outline.svg'],
    ['account-convert', './assets/svg/source/mdi/account-convert.svg'],
    ['account-convert-outline', './assets/svg/source/mdi/account-convert-outline.svg'],
    ['account-credit-card-outline', './assets/svg/source/mdi/account-credit-card-outline.svg'],
    ['account-group', './assets/svg/source/my-icons/account-group.svg'],
    ['account-lock-outline', './assets/svg/source/mdi/account-lock-outline.svg'],
    ['account-off-outline', './assets/svg/source/mdi/account-off-outline.svg'],
    ['account-multiple-check-outline', './assets/svg/source/mdi/account-multiple-check-outline.svg'],
    ['account-outline', './assets/svg/source/mdi/account-outline.svg'],
    ['account-reactivate', './assets/svg/source/mdi/account-reactivate.svg'],
    ['account-reactivate-outline', './assets/svg/source/mdi/account-reactivate-outline.svg'],
    ['account-supervisor-circle-outline', './assets/svg/source/mdi/account-supervisor-circle-outline.svg'],
    ['add-right', './assets/svg/source/generic/add-right.svg'],
    ['alarm-note', './assets/svg/source/mdi/alarm-note.svg'],
    ['alarm-off', './assets/svg/source/mdi/alarm-off.svg'],
    ['animation-outline', './assets/svg/source/mdi/animation-outline.svg'],
    ['arrow', './assets/svg/source/generic/arrow.svg'],
    ['arrow-collapse', './assets/svg/source/mdi/arrow-collapse.svg'],
    ['arrow-expand', './assets/svg/source/mdi/arrow-expand.svg'],
    ['arrow-left-circle', './assets/svg/source/mdi/arrow-left-circle.svg'],
    ['arrow-left-circle-outline', './assets/svg/source/mdi/arrow-left-circle-outline.svg'],
    ['arrow-up', './assets/svg/source/my-icons/arrow-up.svg'],
    ['arrow-up-no-space', './assets/svg/source/my-icons/arrow-up-no-space.svg'],
    ['arrow-up-filled', './assets/svg/source/my-icons/arrow-up-filled.svg'],
    ['arrow-up-circle', './assets/svg/source/my-icons/arrow-up-circle.svg'],
    ['arrow-down', './assets/svg/source/my-icons/arrow-down.svg'],
    ['arrow-down-no-space', './assets/svg/source/my-icons/arrow-down-no-space.svg'],
    ['arrow-down-filled', './assets/svg/source/my-icons/arrow-down-filled.svg'],
    ['arrow-down-circle', './assets/svg/source/my-icons/arrow-down-circle.svg'],
    ['arrow-left', './assets/svg/source/my-icons/arrow-left.svg'],
    ['arrow-right', './assets/svg/source/my-icons/arrow-right.svg'],
    ['attention', './assets/svg/source/generic/attention.svg'],
    ['bed', './assets/svg/source/mdi/bed.svg'],
    ['bed-outline', './assets/svg/source/mdi/bed-outline.svg'],
    ['bell', './assets/svg/source/generic/bell.svg'],
    ['book-clock-outline', './assets/svg/source/mdi/book-clock-outline.svg'],
    ['bookmark-multiple-outline', './assets/svg/source/mdi/bookmark-multiple-outline.svg'], // booking
    ['bookmark-off-outline', './assets/svg/source/mdi/bookmark-off-outline.svg'],
    ['bookshelf', './assets/svg/source/mdi/bookshelf.svg'],
    ['call-split', './assets/svg/source/mdi/call-split.svg'],
    ['calendar-month', './assets/svg/source/generic/calendar-month.svg'],
    ['calendar-month-outline', './assets/svg/source/mdi/calendar-month-outline.svg'],
    ['calendar-outline', './assets/svg/source/mdi/calendar-outline.svg'],
    ['calendar-range-outline', './assets/svg/source/mdi/calendar-range-outline.svg'],
    ['cancel', './assets/svg/source/generic/cancel.svg'],
    ['cake-variant', './assets/svg/source/mdi/cake-variant.svg'],
    ['cake-variant-outline', './assets/svg/source/mdi/cake-variant-outline.svg'],
    ['campaign-reload', './assets/svg/source/mdi/campaign-reload.svg'],
    ['campaign-speaker', './assets/svg/source/mdi/campaign-speaker.svg'],
    ['campaign-calendar', './assets/svg/source/mdi/campaign-calendar.svg'],
    ['campaign-download', './assets/svg/source/generic/campaign-download.svg'],
    ['cell-phone', './assets/svg/source/generic/cell-phone.svg'],
    ['chart-bar-stacked', './assets/svg/source/mdi/chart-bar-stacked.svg'],
    ['checkbox', './assets/svg/source/my-icons/checkbox.svg'],
    ['check-circle-outline', './assets/svg/source/mdi/check-circle-outline.svg'],
    ['chevron-triple-down', './assets/svg/source/mdi/chevron-triple-down.svg'],
    ['client-portal', './assets/svg/source/generic/client-portal.svg'],
    ['clipboard-text', './assets/svg/source/mdi/clipboard-text.svg'],
    ['clipboard-text-outline', './assets/svg/source/mdi/clipboard-text-outline.svg'],
    ['clock-outline', './assets/svg/source/mdi/clock-outline.svg'],
    ['clock-time-one-outline', './assets/svg/source/mdi/clock-time-one-outline.svg'],
    ['clock-time-three-outline', './assets/svg/source/mdi/clock-time-three-outline.svg'],
    ['clock-time-two', './assets/svg/source/mdi/clock-time-two.svg'],
    ['clock-early', './assets/svg/source/generic/clock-early.svg'],
    ['clock-late', './assets/svg/source/generic/clock-late.svg'],
    ['clock-anytime', './assets/svg/source/generic/clock-anytime.svg'],
    ['close', './assets/svg/source/generic/close.svg'],
    ['close-white', './assets/svg/source/generic/close-white.svg'],
    ['close-circle-outline', './assets/svg/source/mdi/close-circle-outline.svg'],
    ['close-thin', './assets/svg/source/generic/close-thin.svg'],
    ['cog', './assets/svg/source/mdi/cog.svg'],
    ['cog-outline', './assets/svg/source/mdi/cog-outline.svg'],
    ['company', './assets/svg/source/generic/company.svg'],
    ['confirm', './assets/svg/source/generic/confirm.svg'],
    ['contact', './assets/svg/source/generic/contact.svg'],
    ['content-copy', './assets/svg/source/mdi/content-copy.svg'],
    ['content-save-outline', './assets/svg/source/mdi/content-save-outline.svg'],
    ['credit-card', './assets/svg/source/generic/credit-card.svg'],
    ['credit-card-clock-outline', './assets/svg/source/mdi/credit-card-clock-outline.svg'],
    ['cube-outline', './assets/svg/source/mdi/cube-outline.svg'],
    ['cursor-hand', './assets/svg/source/my-icons/cursor-hand.svg'],
    ['cursor-hand-checkmark', './assets/svg/source/my-icons/cursor-hand-checkmark.svg'],
    ['database', './assets/svg/source/mdi/database.svg'],
    ['delete-alert', './assets/svg/source/mdi/delete-alert.svg'],
    ['delete-alert-outline', './assets/svg/source/mdi/delete-alert-outline.svg'],
    ['delete-empty-outline', './assets/svg/source/mdi/delete-empty-outline.svg'],
    ['delete-outline', './assets/svg/source/mdi/delete-outline.svg'],
    ['doubles-bed', './assets/svg/source/generic/doubles-bed.svg'],
    ['documentation', './assets/svg/source/generic/documentation.svg'],
    ['dots-vertical', './assets/svg/source/mdi/dots-vertical.svg'],
    ['download-filled', './assets/svg/source/generic/download-filled.svg'],
    ['download', './assets/svg/source/mdi/download.svg'],
    ['download-blue', './assets/svg/source/generic/download-blue.svg'],
    ['done', './assets/svg/source/generic/done.svg'],
    ['drag-and-drop', './assets/svg/source/my-icons/drag-and-drop.svg'],
    ['drag-and-drop-vertical', './assets/svg/source/my-icons/drag-and-drop-vertical.svg'],
    ['dropdown', './assets/svg/source/my-icons/dropdown.svg'],
    ['pagination-back', './assets/svg/source/generic/pagination-back.svg'],
    ['pagination-next', './assets/svg/source/generic/pagination-next.svg'],
    ['edit', './assets/svg/source/my-icons/edit.svg'],
    ['email', './assets/svg/source/my-icons/email.svg'],
    ['email-authorization', './assets/svg/source/my-icons/email-authorization.svg'],
    ['email-send', './assets/svg/source/generic/email-send.svg'],
    ['email-fast', './assets/svg/source/generic/email-fast.svg'],
    ['email-multiple-outline', './assets/svg/source/mdi/email-multiple-outline.svg'],
    ['email-plus-outline', './assets/svg/source/mdi/email-plus-outline.svg'],
    ['email-sync-outline', './assets/svg/source/mdi/email-sync-outline.svg'],
    ['eye-off', './assets/svg/source/mdi/eye-off.svg'],
    ['eye-outline', './assets/svg/source/mdi/eye-outline.svg'],
    ['eye-open', './assets/svg/source/generic/eye-open.svg'],
    ['fast-track', './assets/svg/source/generic/fast-track.svg'],
    ['fax', './assets/svg/source/my-icons/fax.svg'],
    ['file-chart', './assets/svg/source/mdi/file-chart.svg'],
    ['file-chart-outline', './assets/svg/source/mdi/file-chart-outline.svg'],
    ['file-document', './assets/svg/source/mdi/file-document.svg'],
    ['file-document-outline', './assets/svg/source/mdi/file-document-outline.svg'],
    ['file-import-outline', './assets/svg/source/mdi/file-import-outline.svg'],
    ['fit-to-page-outline', './assets/svg/source/mdi/fit-to-page-outline.svg'],
    ['filter-off-outline', './assets/svg/source/mdi/filter-off-outline.svg'],
    ['foodie', './assets/svg/source/generic/foodie.svg'],
    ['format-rotate-90', './assets/svg/source/mdi/format-rotate-90.svg'],
    ['gender-female', './assets/svg/source/mdi/gender-female.svg'],
    ['gender-male', './assets/svg/source/mdi/gender-male.svg'],
    ['gender-non-binary', './assets/svg/source/mdi/gender-non-binary.svg'],
    ['group-contract', './assets/svg/source/generic/group-contract.svg'],
    ['group-no-contract', './assets/svg/source/generic/group-no-contract.svg'],
    ['help-circle-outline', './assets/svg/source/mdi/help-circle-outline.svg'],
    ['hiking', './assets/svg/source/generic/hiking.svg'],
    ['history', './assets/svg/source/mdi/history.svg'],
    ['home', './assets/svg/source/mdi/home.svg'],
    ['human-female', './assets/svg/source/mdi/human-female.svg'],
    ['human-male', './assets/svg/source/mdi/human-male.svg'],
    ['human-greeting-proximity', './assets/svg/source/mdi/human-greeting-proximity.svg'],
    ['image', './assets/svg/source/my-icons/image.svg'],
    ['image-multiple-outline', './assets/svg/source/mdi/image-multiple-outline.svg'],
    ['image-filter-center-focus', './assets/svg/source/mdi/image-filter-center-focus.svg'],
    ['image-off-outline', './assets/svg/source/mdi/image-off-outline.svg'],
    ['itinerary', './assets/svg/source/my-icons/itinerary.svg'],
    ['king-bed', './assets/svg/source/generic/king-bed.svg'],
    ['link', './assets/svg/source/mdi/link.svg'],
    ['link-off', './assets/svg/source/mdi/link-off.svg'],
    ['link-plus', './assets/svg/source/mdi/link-plus.svg'],
    ['lock-check', './assets/svg/source/mdi/lock-check.svg'],
    ['lock-check-outline', './assets/svg/source/mdi/lock-check-outline.svg'],
    ['lock-open-outline', './assets/svg/source/mdi/lock-open-outline.svg'],
    ['lock-reset', './assets/svg/source/mdi/lock-reset.svg'],
    ['magnify', './assets/svg/source/mdi/magnify.svg'],
    ['magnify-minus-outline', './assets/svg/source/mdi/magnify-minus-outline.svg'],
    ['magnify-plus-outline', './assets/svg/source/mdi/magnify-plus-outline.svg'],
    ['navigation-right-arrow', './assets/svg/source/mdi/navigation-right-arrow.svg'],
    ['navigation-left-arrow', './assets/svg/source/mdi/navigation-left-arrow.svg'],
    ['navigation-start-arrow', './assets/svg/source/mdi/navigation-start-arrow.svg'],
    ['map-marker', './assets/svg/source/mdi/map-marker.svg'],
    ['map-marker2', './assets/svg/source/generic/map-marker.svg'],
    ['menu', './assets/svg/source/mdi/menu.svg'],
    ['more', './assets/svg/source/generic/more.svg'],
    ['more-vert', './assets/svg/source/generic/more-vert.svg'],
    ['mountains', './assets/svg/source/generic/mountains.svg'],
    ['move', './assets/svg/source/generic/move.svg'],
    ['one-to-one', './assets/svg/source/generic/one-to-one.svg'],
    ['pagination-back', './assets/svg/source/generic/pagination-back.svg'],
    ['pagination-next', './assets/svg/source/generic/pagination-next.svg'],
    ['passport', './assets/svg/source/mdi/passport.svg'],
    ['pen', './assets/svg/source/generic/pen.svg'],
    ['pin', './assets/svg/source/my-icons/pin.svg'],
    ['playlist-plus', './assets/svg/source/mdi/playlist-plus.svg'],
    ['progress-alert', './assets/svg/source/mdi/progress-alert.svg'],
    ['progress-check', './assets/svg/source/mdi/progress-check.svg'],
    ['progress-clock', './assets/svg/source/mdi/progress-clock.svg'],
    ['proposal', './assets/svg/source/generic/proposal.svg'],
    ['point', './assets/svg/source/generic/point.svg'],
    ['queen-bed', './assets/svg/source/generic/queen-bed.svg'],
    ['query-builder', './assets/svg/source/my-icons/query-builder.svg'],
    ['refresh', './assets/svg/source/mdi/refresh.svg'],
    ['refresh-circle', './assets/svg/source/mdi/refresh-circle.svg'],
    ['standard-trip', './assets/svg/source/generic/standard-trip.svg'],
    ['relationship', './assets/svg/source/generic/relationship.svg'],
    ['report-commission', './assets/svg/source/generic/report-commission.svg'],
    ['report-custom', './assets/svg/source/generic/report-custom.svg'],
    ['report-trip', './assets/svg/source/generic/report-trip.svg'],
    ['reservation', './assets/svg/source/generic/reservation.svg'],
    ['save', './assets/svg/source/generic/save.svg'],
    ['save-thin', './assets/svg/source/generic/save-thin.svg'],
    ['school-outline', './assets/svg/source/mdi/school-outline.svg'],
    ['seaside', './assets/svg/source/generic/seaside.svg'],
    ['send', './assets/svg/source/mdi/send.svg'],
    ['show-all', './assets/svg/source/generic/show-all.svg'],
    ['silohuette', './assets/svg/source/generic/silohuette.svg'],
    ['skip-next-outline', './assets/svg/source/mdi/skip-next-outline.svg'],
    ['skip-previous-outline', './assets/svg/source/mdi/skip-previous-outline.svg'],
    ['social-networking', './assets/svg/source/generic/social-networking.svg'],
    ['spending-habit', './assets/svg/source/generic/spending-habit.svg'],
    ['summary', './assets/svg/source/generic/summary.svg'],
    ['star-circle-outline', './assets/svg/source/mdi/star-circle-outline.svg'],
    ['stretch-to-page', './assets/svg/source/mdi/stretch-to-page.svg'],
    ['subdirectory-arrow-right', './assets/svg/source/mdi/subdirectory-arrow-right.svg'],
    ['surfing', './assets/svg/source/generic/surfing.svg'],
    ['send-email', './assets/svg/source/generic/send-email.svg'],
    ['search', './assets/svg/source/generic/search.svg'],
    ['search-initial', './assets/svg/source/my-icons/search-initial.svg'],
    ['search-no-records', './assets/svg/source/my-icons/search-no-records.svg'],
    ['teach', './assets/svg/source/mdi/teach.svg'],
    ['telephone', './assets/svg/source/my-icons/telephone.svg'],
    ['text', './assets/svg/source/generic/text.svg'],
    ['text-input', './assets/svg/source/my-icons/text-input.svg'],
    ['three-bed', './assets/svg/source/generic/three-bed.svg'],
    ['timelapse', './assets/svg/source/mdi/timelapse.svg'],
    ['tutorial', './assets/svg/source/generic/tutorial.svg'],
    ['travelers', './assets/svg/source/generic/travelers.svg'],
    ['train', './assets/svg/map/train.svg'],
    ['trophy-award', './assets/svg/source/mdi/trophy-award.svg'],
    ['twins-bed', './assets/svg/source/generic/twins-bed.svg'],
    ['umbrella-beach', './assets/svg/source/mdi/umbrella-beach.svg'],
    ['undecided', './assets/svg/source/generic/undecided.svg'],
    ['undecided-filled', './assets/svg/source/generic/undecided-filled.svg'],
    ['union', './assets/svg/source/generic/union.svg'],
    ['upgrade', './assets/svg/source/generic/upgrade.svg'],
    ['view-grid-plus', './assets/svg/source/mdi/view-grid-plus.svg'],
    ['wallet-travel', './assets/svg/source/mdi/wallet-travel.svg'],
    ['wintersports', './assets/svg/source/generic/wintersports.svg'],
    ['yachting', './assets/svg/source/generic/yachting.svg'],
    ['youtube-subscription', './assets/svg/source/mdi/youtube-subscription.svg'],
    ['recurrence', './assets/svg/source/my-icons/recurrence.svg'],
    ['status', './assets/svg/source/my-icons/status.svg'],
    ['type', './assets/svg/source/my-icons/type.svg'],
    ['input-format', './assets/svg/source/my-icons/input-format.svg'],
    ['required-field', './assets/svg/source/my-icons/required-field.svg'],
    ['dropdown-options', './assets/svg/source/my-icons/dropdown-options.svg'],
    ['social', './assets/svg/source/my-icons/social.svg'],
    ['divider', './assets/svg/source/my-icons/divider.svg'],
    ['spacer', './assets/svg/source/my-icons/spacer.svg'],
    ['icon', './assets/svg/source/my-icons/icon.svg'],

    ['anniversary', './assets/svg/source/occasion/anniversary.svg'],
    ['business-trip', './assets/svg/source/occasion/business-trip.svg'],
    ['honeymoon', './assets/svg/source/occasion/honeymoon.svg'],
    ['vacation', './assets/svg/source/occasion/vacation.svg'],
    ['wedding', './assets/svg/source/occasion/wedding.svg'],

    ['other', './assets/svg/source/my-icons/other.svg'],

    ['status-active', './assets/svg/source/statuses/status-active.svg'],
    ['status-inactive', './assets/svg/source/statuses/status-inactive.svg'],

    ['reward-program', './assets/svg/source/my-icons/reward-program.svg'],
    ['client-referral', './assets/svg/source/my-icons/client-referral.svg'],
    ['referral-group', './assets/svg/source/my-icons/referral-group.svg'],

    ['car', './assets/svg/source/suppliers/car.svg'],
    ['car-rental-check-in', './assets/svg/source/suppliers/car-rental-check-in.svg'],
    ['car-rental-check-out', './assets/svg/source/suppliers/car-rental-check-out.svg'],
    ['cruise', './assets/svg/source/suppliers/cruise.svg'],
    ['cruise-check-in', './assets/svg/source/suppliers/cruise-check-in.svg'],
    ['cruise-check-out', './assets/svg/source/suppliers/cruise-check-out.svg'],
    ['dining', './assets/svg/source/suppliers/dining.svg'],
    ['hotel', './assets/svg/source/suppliers/hotel.svg'],
    ['hotel-check-in', './assets/svg/source/suppliers/hotel-check-in.svg'],
    ['hotel-check-out', './assets/svg/source/suppliers/hotel-check-out.svg'],
    ['excursion', './assets/svg/source/suppliers/excursion.svg'],
    ['fastpass', './assets/svg/source/suppliers/fast-pass.svg'],
    ['flight', './assets/svg/source/suppliers/flight.svg'],
    ['flight-departure', './assets/svg/source/suppliers/flight-departure.svg'],
    ['flight-arrival', './assets/svg/source/suppliers/flight-arrival.svg'],
    ['information', './assets/svg/source/suppliers/information.svg'],
    ['rail', './assets/svg/source/suppliers/rail.svg'],
    ['tickets', './assets/svg/source/suppliers/tickets.svg'],
    ['tour', './assets/svg/source/suppliers/tour.svg'],
    ['tour-check-in', './assets/svg/source/suppliers/tour-check-in.svg'],
    ['tour-check-out', './assets/svg/source/suppliers/tour-check-out.svg'],
    ['transfer', './assets/svg/source/suppliers/transfer.svg'],

    ['chart-column-comparison-placeholder', './assets/svg/source/placeholders/chart-column-comparison-placeholder.svg'],
    ['commissions-placeholder', './assets/svg/source/placeholders/commissions-placeholder.svg'],
    ['contact-details-placeholder', './assets/svg/source/placeholders/contact-details-placeholder.svg'],
    ['contact-emergency-placeholder', './assets/svg/source/placeholders/contact-emergency-placeholder.svg'],
    ['contact-relationship-placeholder', './assets/svg/source/placeholders/contact-relationship-placeholder.svg'],
    ['credit-card-placeholder', './assets/svg/source/placeholders/credit-card-placeholder.svg'],
    ['documents-placeholder', './assets/svg/source/placeholders/documents-placeholder.svg'],
    ['goals-placeholder', './assets/svg/source/placeholders/goals-placeholder.svg'],
    ['images-placeholder', './assets/svg/source/placeholders/images-placeholder.svg'],
    ['notes-placeholder', './assets/svg/source/placeholders/notes-placeholder.svg'],
    ['sales-goals-placeholder', './assets/svg/source/placeholders/sales-goals-placeholder.svg'],
    ['sales-placeholder', './assets/svg/source/placeholders/sales-placeholder.svg'],
    ['spending-habit-placeholder', './assets/svg/source/placeholders/spending-habit-placeholder.svg'],
    ['tasklist-placeholder', './assets/svg/source/placeholders/tasklist-placeholder.svg'],
    ['todo-placeholder', './assets/svg/source/placeholders/todo-placeholder.svg'],
    ['travelers-placeholder', './assets/svg/source/placeholders/travelers-placeholder.svg'],
    ['trip-list-placeholder', './assets/svg/source/placeholders/trip-list-placeholder.svg'],
    ['trip-itinerary-placeholder', './assets/svg/source/placeholders/trip-itinerary-placeholder.svg'],
    ['workflow-placeholder', './assets/svg/source/placeholders/workflow-placeholder.svg'],

    ['administration', './assets/svg/source/menu/administration.svg'],
    ['arrow-backward', './assets/svg/source/menu/arrow-backward.svg'],
    ['arrow-forward', './assets/svg/source/menu/arrow-forward.svg'],
    ['circle', './assets/svg/source/menu/circle.svg'],
    ['dashboard', './assets/svg/source/menu/dashboard.svg'],
    ['documentation', './assets/svg/source/menu/documentation.svg'],
    ['inbox', './assets/svg/source/menu/inbox.svg'],
    ['calendar', './assets/svg/source/menu/calendar.svg'],
    ['my-tasks', './assets/svg/source/menu/my-tasks.svg'],
    ['client-management', './assets/svg/source/menu/client-management.svg'],
    ['agency-portal', './assets/svg/source/menu/agency-portal.svg'],
    ['trip-management', './assets/svg/source/menu/trip-management.svg'],
    ['commission', './assets/svg/source/menu/commission.svg'],
    ['suppliers', './assets/svg/source/menu/suppliers.svg'],
    ['reports', './assets/svg/source/menu/reports.svg'],
    ['my-account', './assets/svg/source/menu/my-account.svg'],
    ['my-company', './assets/svg/source/menu/my-company.svg'],
    ['settings', './assets/svg/source/menu/settings.svg'],
    ['helpdesk', './assets/svg/source/menu/helpdesk.svg'],
    ['library', './assets/svg/source/menu/library.svg'],
    ['templates', './assets/svg/source/menu/templates.svg'],
    ['marketing', './assets/svg/source/menu/marketing.svg'],
    ['events', './assets/svg/source/menu/events.svg'],
    ['payments-due', './assets/svg/source/menu/payments-due.svg'],
    ['waving-hand', './assets/svg/source/menu/waving-hand.svg'],
    ['logout', './assets/svg/source/menu/logout.svg'],
    ['workflow', './assets/svg/source/menu/workflow.svg'],

    ['adjusted-availability', './assets/svg/source/calendar/adjusted-availability.svg'],
    ['appointment-duration', './assets/svg/source/calendar/duration.svg'],
    ['appointment-settings', './assets/svg/source/calendar/appointment-settings.svg'],
    ['availability', './assets/svg/source/calendar/availability.svg'],
    ['booking-form', './assets/svg/source/calendar/booking-form.svg'],
    ['booking-reminder', './assets/svg/source/calendar/booking-reminder.svg'],
    ['color', './assets/svg/source/calendar/color.svg'],
    ['description', './assets/svg/source/calendar/description.svg'],
    ['general-availability', './assets/svg/source/calendar/general-availability.svg'],
    ['link', './assets/svg/source/calendar/link.svg'],
    ['location', './assets/svg/source/calendar/location.svg'],
    ['location-none', './assets/svg/source/calendar/location-none.svg'],
    ['location-circle', './assets/svg/source/calendar/location-circle.svg'],
    ['scheduling-window', './assets/svg/source/calendar/scheduling-window.svg'],
    ['title', './assets/svg/source/calendar/title.svg'],

    ['airline-business', './assets/svg/source/airline/business.svg'],
    ['airline-economy', './assets/svg/source/airline/economy.svg'],
    ['airline-first', './assets/svg/source/airline/first.svg'],
    ['airline-premium-economy', './assets/svg/source/airline/premium-economy.svg'],
    ['airline-suite', './assets/svg/source/airline/suite.svg'],
    ['seat-aisle', './assets/svg/source/airline/seat-aisle.svg'],
    ['seat-middle', './assets/svg/source/airline/seat-middle.svg'],
    ['seat-window', './assets/svg/source/airline/seat-window.svg'],

    ['car-full-size', './assets/svg/source/car/full-size.svg'],
    ['car-sports-car', './assets/svg/source/car/sports-car.svg'],
    ['car-filled', './assets/svg/source/car/filled.svg'],
    ['car-economy', './assets/svg/source/car/economy.svg'],
    ['car-electric-vehicle', './assets/svg/source/car/electric-vehicle.svg'],
    ['car-exotic', './assets/svg/source/car/exotic.svg'],
    ['car-luxury', './assets/svg/source/car/luxury.svg'],
    ['car-mid-size', './assets/svg/source/car/mid-size.svg'],
    ['car-suv', './assets/svg/source/car/suv.svg'],
    ['car-minivan', './assets/svg/source/car/minivan.svg'],
    ['transfer-private', './assets/svg/source/car/transfer-private.svg'],
    ['transfer-car-rental', './assets/svg/source/car/transfer-car-rental.svg'],
    ['transfer-shared', './assets/svg/source/car/transfer-shared.svg'],

    ['cruise-ship-aft', './assets/svg/source/cruise-ship/aft.svg'],
    ['cruise-ship-balcony', './assets/svg/source/cruise-ship/balcony.svg'],
    ['cruise-ship-bow', './assets/svg/source/cruise-ship/bow.svg'],
    ['cruise-ship-low-deck', './assets/svg/source/cruise-ship/low-deck.svg'],
    ['cruise-ship-high-deck', './assets/svg/source/cruise-ship/high-deck.svg'],
    ['cruise-ship-interior', './assets/svg/source/cruise-ship/interior.svg'],
    ['cruise-ship-interior-balcony', './assets/svg/source/cruise-ship/interior-balcony.svg'],
    ['cruise-ship-midship', './assets/svg/source/cruise-ship/midship.svg'],
    ['cruise-ship-oceanview', './assets/svg/source/cruise-ship/oceanview.svg'],
    ['cruise-ship-panoramic', './assets/svg/source/cruise-ship/panoramic.svg'],
    ['cruise-ship-suite', './assets/svg/source/cruise-ship/suite.svg'],

    ['hotel-high-floor', './assets/svg/source/hotel/high-floor.svg'],
    ['hotel-low-floor', './assets/svg/source/hotel/low-floor.svg'],
    ['hotel-preference', './assets/svg/source/hotel/preference.svg'],

    ['assistance-accessible-room', './assets/svg/source/assistance/accessible-room.svg'],
    ['assistance-autism', './assets/svg/source/assistance/autism.svg'],
    ['assistance-cognitive-disability', './assets/svg/source/assistance/cognitive-disability.svg'],
    ['assistance-hearing-impaired', './assets/svg/source/assistance/hearing-impaired.svg'],
    ['assistance-not-required', './assets/svg/source/assistance/not-required.svg'],
    ['assistance-other', './assets/svg/source/assistance/other.svg'],
    ['assistance-oxygen-tank', './assets/svg/source/assistance/oxygen-tank.svg'],
    ['assistance-peanut-allergy', './assets/svg/source/assistance/peanut-allergy.svg'],
    ['assistance-pregnant', './assets/svg/source/assistance/pregnant.svg'],
    ['assistance-reduced-mobility', './assets/svg/source/assistance/reduced-mobility.svg'],
    ['assistance-required', './assets/svg/source/assistance/required.svg'],
    ['assistance-service-animal', './assets/svg/source/assistance/service-animal.svg'],
    ['assistance-visually-impaired', './assets/svg/source/assistance/visually-impaired.svg'],
    ['assistance-wheelchair', './assets/svg/source/assistance/wheelchair.svg'],

    ['american-express', './assets/svg/source/credit-cards/american-express.svg'],
    ['credit-card-back', './assets/svg/source/credit-cards/credit-card-back.svg'],
    ['credit-card-front', './assets/svg/source/credit-cards/credit-card-front.svg'],
    ['diners-club', './assets/svg/source/credit-cards/diners-club.svg'],
    ['discover', './assets/svg/source/credit-cards/discover.svg'],
    ['elo', './assets/svg/source/credit-cards/elo.svg'],
    ['hiper', './assets/svg/source/credit-cards/hiper.svg'],
    ['jcb', './assets/svg/source/credit-cards/jcb.svg'],
    ['maestro', './assets/svg/source/credit-cards/maestro.svg'],
    ['mastercard', './assets/svg/source/credit-cards/mastercard.svg'],
    ['mir', './assets/svg/source/credit-cards/mir.svg'],
    ['union-pay', './assets/svg/source/credit-cards/union-pay.svg'],
    ['visa', './assets/svg/source/credit-cards/visa.svg'],

    ['google', './assets/svg/source/logos/google.svg'],
    ['google-meet', './assets/svg/source/logos/google-meet.svg'],
    ['icloud', './assets/svg/source/logos/apple-icloud.svg'],
    ['microsoft', './assets/svg/source/logos/microsoft.svg'],
    ['microsoft-teams', './assets/svg/source/logos/microsoft-teams.svg'],
    ['webex', './assets/svg/source/logos/webex.svg'],
    ['zoom', './assets/svg/source/logos/zoom.svg'],
    ['facebook', './assets/svg/source/logos/facebook.svg'],
    ['pinterest', './assets/svg/source/logos/pinterest.svg'],
    ['tumblr', './assets/svg/source/logos/tumblr.svg'],
    ['your-company-logo', './assets/svg/source/logos/your-company-logo.svg'],
    ['your-company-logo-icon', './assets/svg/source/logos/your-company-logo-icon.svg'],

    ['checkmark', './assets/svg/source/my-icons/checkmark.svg'],

    ['copy', './assets/svg/source/my-icons/copy.svg'],
    ['plus', './assets/svg/source/my-icons/plus.svg'],
    ['plus-square', './assets/svg/source/my-icons/plus-square.svg'],
    ['plus-circle', './assets/svg/source/my-icons/plus-circle.svg'],

    ['editor-align-left', './assets/svg/source/html-editor/align-left.svg'],
    ['editor-a-font', './assets/svg/source/html-editor/color-picker.svg'],
    ['editor-b-font', './assets/svg/source/html-editor/bold.svg'],
    ['editor-t-font', './assets/svg/source/html-editor/text-edit.svg'],
    ['editor-i-font', './assets/svg/source/html-editor/italic.svg'],
    ['editor-u-font', './assets/svg/source/html-editor/underline.svg'],
    ['editor-undo', './assets/svg/source/html-editor/arrow-undo-left.svg'],
    ['editor-copy-file', './assets/svg/source/html-editor/documents-file-blank.svg'],
    ['editor-cross-a-font', './assets/svg/source/html-editor/pen-cross-delete.svg'],
    ['editor-double-arrow', './assets/svg/source/html-editor/code-text.svg'],
    ['editor-link-font', './assets/svg/source/html-editor/attachment-link.svg'],
    ['editor-redo', './assets/svg/source/html-editor/arrow-undo-right.svg'],
    ['editor-number-list', './assets/svg/source/html-editor/list-numbers.svg'],
    ['editor-point-list', './assets/svg/source/html-editor/list-paragraph-dots-checkmark.svg'],
    ['editor-quotes', '/assets/svg/source/html-editor/quote.svg'],
    ['text-edit', './assets/svg/source/html-editor/text-edit-a.svg'],

    ['liked-destination', './assets/svg/source/mdi/liked-destination.svg'],
    ['avoid-destination', './assets/svg/source/mdi/avoid-destination.svg'],
    ['travel-interest', './assets/svg/source/mdi/travel-interest.svg'],
    ['special-needs', './assets/svg/source/mdi/special-needs.svg'],
    ['car-rental-agency', './assets/svg/source/mdi/car-rental-agency.svg'],
    ['car-rental-type', './assets/svg/source/mdi/car-rental-type.svg'],
    ['hotel-brand', './assets/svg/source/mdi/hotel-brand.svg'],
    ['bedding', './assets/svg/source/mdi/bedding.svg'],
    ['seat-assignment', './assets/svg/source/mdi/seat-assignment.svg'],
    ['cruise-dining', './assets/svg/source/mdi/cruise-dining.svg'],

    ['refresh', './assets/svg/source/generic/refresh.svg'],
    ['warning', './assets/svg/source/generic/warning.svg'],

    ['layout', './assets/svg/layouts/layout.svg'],
    ['layout-whole', './assets/svg/layouts/whole.svg'],
    ['layout-halves', './assets/svg/layouts/halves.svg'],
    ['layout-thirds', './assets/svg/layouts/thirds.svg'],
    ['layout-thirds-1-2', './assets/svg/layouts/thirds-1-2.svg'],
    ['layout-thirds-2-1', './assets/svg/layouts/thirds-2-1.svg'],
    ['layout-quarters', './assets/svg/layouts/quarters.svg'],
    ['layout-quarters-1-1-2', './assets/svg/layouts/quarters-1-1-2.svg'],
    ['layout-quarters-1-2-1', './assets/svg/layouts/quarters-1-2-1.svg'],
    ['layout-quarters-1-3', './assets/svg/layouts/quarters-1-3.svg'],
    ['layout-quarters-2-1-1', './assets/svg/layouts/quarters-2-1-1.svg'],
    ['layout-quarters-3-1', './assets/svg/layouts/quarters-3-1.svg'],

    ['sign-in-profile', './assets/svg/sign-in/profile.svg'],
    ['sign-in-password', './assets/svg/sign-in/password.svg'],
    ['sign-in-company', './assets/svg/sign-in/company.svg'],
    ['sign-in-eye-on', './assets/svg/sign-in/password-eye-on.svg'],
    ['sign-in-eye-off', './assets/svg/sign-in/password-eye-off.svg'],
    ['sign-in-eye2-on', './assets/svg/sign-in/password-eye2-on.svg'],
    ['sign-in-eye2-off', './assets/svg/sign-in/password-eye2-off.svg'],
    ['sign-in-error-reject', './assets/svg/sign-in/error-reject.svg'],
    ['sign-in-worldwide', './assets/svg/sign-in/worldwide.svg'],
    ['sign-in-worldwide-white', './assets/svg/sign-in/worldwide-white.svg'],
    ['sign-in-edit-pen', './assets/svg/sign-in/edit-pen.svg'],
    ['sign-in-check', './assets/svg/sign-in/check.svg'],
    ['sign-in-check-large', './assets/svg/sign-in/check-large.svg'],
    ['sign-in-check-large2', './assets/svg/sign-in/check-large2.svg'],
    ['sign-in-rubbish-bin', './assets/svg/sign-in/rubbish-bin.svg'],
    ['sign-in-email', './assets/svg/sign-in/email.svg'],
    ['sign-in-envelope', './assets/svg/sign-in/envelope.svg'],
    ['sign-in-tess-logo', './assets/svg/sign-in/tess-logo.svg'],
    ['sign-in-tess-logo2', './assets/svg/sign-in/tess-logo2.svg'],
    ['sign-in-tv2', './assets/svg/sign-in/tv2.svg'],
    ['sign-in-calendar', './assets/svg/sign-in/calendar.svg'],
    ['sign-in-hand-money', './assets/svg/sign-in/hand-money.svg'],
    ['sign-in-clock', './assets/svg/sign-in/clock.svg'],
    ['sign-in-play-video', './assets/svg/sign-in/play-video.svg'],
    ['sign-in-doc', './assets/svg/sign-in/doc.svg'],
    ['sign-in-notice', './assets/svg/sign-in/notice.svg'],
    ['sign-in-dollar', './assets/svg/sign-in/dollar.svg'],
    ['sign-in-credit-card', './assets/svg/sign-in/credit-card.svg'],
    ['sign-in-check2', './assets/svg/sign-in/check2.svg'],
    ['sign-in-card-bankname', './assets/svg/sign-in/card-bankname.svg'],
    ['sign-in-card-cheap', './assets/svg/sign-in/card-cheap.svg'],
    ['sign-in-card-nfc', './assets/svg/sign-in/card-nfc.svg'],
    ['sign-in-card-master', './assets/svg/sign-in/card-master.svg'],
    ['sign-in-dashboard', './assets/svg/sign-in/dashboard.svg'],
    ['sign-in-documentation', './assets/svg/sign-in/documentation.svg'],
    ['sign-in-profile2', './assets/svg/sign-in/profile2.svg'],
    ['sign-in-logout', './assets/svg/sign-in/logout.svg'],
    ['sign-in-phone', './assets/svg/sign-in/phone.svg'],

    ['dashboard-agent-tasks', './assets/svg/source/dashboard/dashboard-agent-tasks.svg'],
    ['dashboard-booking', './assets/svg/source/dashboard/dashboard-booking.svg'],
    ['dashboard-chart', './assets/svg/source/dashboard/dashboard-chart.svg'],
    ['dashboard-client-tasks', './assets/svg/source/dashboard/dashboard-client-tasks.svg'],
    ['dashboard-inquiries', './assets/svg/source/dashboard/dashboard-inquiries.svg'],
    ['dashboard-meetings', './assets/svg/source/dashboard/dashboard-meetings.svg'],
    ['dashboard-trips', './assets/svg/source/dashboard/dashboard-trips.svg'],
    ['dashboard-arrow', './assets/svg/source/dashboard/dashboard-arrow.svg'],
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {
    this.#customIcons.forEach(([iconName, iconPath]) => {
      this.iconRegistry.addSvgIcon(iconName, this.sanitizer.bypassSecurityTrustResourceUrl(iconPath));
    });

    this.iconRegistry.addSvgIconSetInNamespace(
      'mdi',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../assets/svg/mdi-8dd451fe.svg'),
    );
    // TODO: nicer looking flags than flagkit, however it seems quite heavy.
    this.iconRegistry.addSvgIconSetInNamespace(
      'flag',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../assets/svg/flags-c084ae67.svg'),
    );
    this.iconRegistry.addSvgIconSetInNamespace(
      'flagkit',
      this.sanitizer.bypassSecurityTrustResourceUrl('../../assets/svg/flagkit-5d650c45.svg'),
    );
  }
}
