import { ComponentPortal } from '@angular/cdk/portal';
import {
  Directive,
  ElementRef,
  inject,
  Injector,
  input,
  OnDestroy,
  type TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Overlay, type OverlayRef, type PositionStrategy } from '@angular/cdk/overlay';

import { TOOLTIP_DATA, TooltipComponent } from '@core/tooltip/tooltip.component';

@Directive({
  selector: '[tessTooltip]',
  standalone: true,
  host: {
    '(mouseenter)': 'focus()',
    '(mouseleave)': 'blur()',
  },
})
export class TooltipDirective implements OnDestroy {
  tessTooltip = input.required<TemplateRef<void>>();

  private element = inject<ElementRef<HTMLElement>>(ElementRef);
  private overlay = inject(Overlay);
  private viewContainer = inject(ViewContainerRef);
  private overlayRef: OverlayRef | null = null;
  private hideTimeout: ReturnType<typeof setTimeout>;

  ngOnDestroy(): void {
    this.overlayRef?.dispose();
  }

  focus(): void {
    if (this.overlayRef?.hasAttached()) {
      clearTimeout(this.hideTimeout);
      return;
    }

    this.attachTooltip();
  }

  blur(): void {
    this.hideTimeout = setTimeout(() => {
      if (this.overlayRef?.hasAttached()) {
        this.overlayRef?.detach();
      }
    }, 2000);
  }

  private attachTooltip(): void {
    if (this.overlayRef === null) {
      const positionStrategy = this.getPositionStrategy();
      this.overlayRef = this.overlay.create({ positionStrategy });
    }

    const injector = Injector.create({
      providers: [
        {
          provide: TOOLTIP_DATA,
          useValue: {
            focus: () => this.focus(),
            blur: () => this.blur(),
            template: this.tessTooltip(),
          },
        },
      ],
    });
    const component = new ComponentPortal(TooltipComponent, this.viewContainer, injector);
    this.overlayRef.attach(component);
  }

  private getPositionStrategy(): PositionStrategy {
    return this.overlay
      .position()
      .flexibleConnectedTo(this.element)
      .withPositions([
        {
          originX: 'center',
          originY: 'top',
          overlayX: 'center',
          overlayY: 'bottom',
          panelClass: 'top',
        },
        {
          originX: 'center',
          originY: 'bottom',
          overlayX: 'center',
          overlayY: 'top',
          panelClass: 'bottom',
        },
      ]);
  }
}
