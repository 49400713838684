import { ChangeDetectionStrategy, Component, input } from '@angular/core';

import { AvatarComponent } from '@core/images/avatar/avatar.component';
import { User } from '@user/shared/models/user';

@Component({
  selector: 'tess-navigation-account',
  standalone: true,
  imports: [AvatarComponent],
  templateUrl: './navigation-account.component.html',
  styleUrl: './navigation-account.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationAccountComponent {
  user = input.required<User>();
}
