import { HttpEvent, HttpHandlerFn, HttpRequest } from '@angular/common/http';
import { inject } from '@angular/core';

import { BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError } from 'rxjs';

import { AuthService } from 'src/app/auth.service';
import { ConfigurationService } from 'src/app/configuration.service';

import { AuthStore } from '@core/auth/models/auth-store';

export function authInterceptor(req: HttpRequest<unknown>, next: HttpHandlerFn): Observable<HttpEvent<unknown>> {
  const authService = inject(AuthService);
  const configurationService = inject(ConfigurationService);
  const accessTokenSubject$: BehaviorSubject<AuthStore> = new BehaviorSubject<AuthStore>(null);
  const authStore = authService.getToken();
  let refreshingInProgress = false;

  const addAuthorizationHeader = (authStore: AuthStore) => {
    // Build the parameters.
    let params = req.params.set('iana', configurationService?.settings?.TimeZone);
    // Remove undefined/ null parameters, and modify date format.
    for (const key of params.keys()) {
      const paramValue = params.get(key);

      if (!paramValue || paramValue === 'undefined' || paramValue === 'null') {
        params = params.delete(key, undefined);
      }
    }

    // Set nocache parameter.
    if (req.method === 'GET' && req.url.indexOf('/api/') >= 0) {
      params = params.set('nocache', `${new Date().getTime()}`);
    }

    if (authStore?.accessToken) {
      // Set the headers.
      let headers = req.headers;
      headers = headers.set('Authorization', `Bearer ${authStore.accessToken}`);
      // Do not override content-type if this is a form data.
      headers = headers.has('tess-isformdata')
        ? headers.delete('tess-isformdata')
        : headers.set('content-type', 'application/json');

      return req.clone({
        headers,
        params,
      });
    }

    return req.clone({
      headers: req.headers.set('content-type', 'application/json'),
      params,
    });
  };

  const logoutAndRedirect = (error: any) => {
    authService.logOut();
    return throwError(() => new Error(error));
  };

  const refreshToken = () => {
    if (!refreshingInProgress) {
      refreshingInProgress = true;
      accessTokenSubject$.next(null);

      return authService.refreshToken().pipe(
        switchMap((authStore: AuthStore) => {
          refreshingInProgress = false;
          accessTokenSubject$.next(authStore);
          // Repeat failed requests with the new token.
          return next(addAuthorizationHeader(authStore));
        }),
      );
    }
    // Wait for the new token.
    return accessTokenSubject$.pipe(
      filter((authStore: AuthStore) => authStore !== null),
      take(1),
      switchMap((authStore: AuthStore) => {
        // Repeat failed requests with the new token.
        return next(addAuthorizationHeader(authStore));
      }),
    );
  };

  if (req.url.indexOf('graph.microsoft.com') > -1 || req.url.indexOf('icloud.com') > -1) {
    return next(req);
  }

  // TODO: refresh if the token is expired.

  return next(addAuthorizationHeader(authStore)).pipe(
    catchError(error => {
      switch (error.status) {
        // 401- Unauthorized.
        case 401: {
          // Retrieve the tokens.
          const authStore = authService.getToken();

          // If the token is not present the refresh either failed, or the user has not logged in yet.
          if (authStore?.refreshToken && authStore?.accessToken) {
            return refreshToken();
          }

          // Logout and redirect to login page.
          return logoutAndRedirect(error);
        }
        // 403- Forbidden.
        case 403:
          return throwError(() => new Error(error));
        // return this.logoutAndRedirect(error);
        default:
          return throwError(() => new Error(error));
      }
    }),
  );
}
