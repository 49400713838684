import { ChangeDetectionStrategy, Component, inject } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { NotificationsService } from 'src/app/notifications.service';

@Component({
  standalone: true,
  selector: 'tess-toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatMenuModule],
})
export class ToolbarNotificationsComponent {
  readonly #notificationsService = inject(NotificationsService);

  readonly notifications = this.#notificationsService.notifications;
}
