import { ResultData } from '@layout/toolbar/toolbar-search/toolbar-search.component';

export class ToolbarSearchMock {
  private static results: ResultData[] = [
    {
      title: 'Clients',
      entries: [
        {
          link: 'views.client.274008.general',
          title: 'Jackson Adams - #274008',
          description: '',
        },
        {
          link: 'views.client.274008.general',
          title: 'Jackson Mark - #7453',
          description: 'Active traveler',
        },
        {
          link: 'views.client.274008.general',
          title: 'Jerry Adams - #56',
          description: '',
        },
        {
          link: 'views.client.274008.general',
          title: 'Jennifer Lopez - #987',
          description: '',
        },
        {
          link: 'views.client.274008.general',
          title: 'Dwayne Johnson - #50007',
          description: 'Happy traveler',
        },
        {
          link: 'views.client.274008.general',
          title: 'Pablo Escobar - #7589',
          description: '',
        },
      ],
    },
    {
      title: 'Bookings',
      entries: [
        {
          link: 'views.client.274008.general',
          title: 'Panama Cruise - MX- ZHK',
          description:
            'John F Kennedy International Airport Boeing 737-900 Passenger Operated by Delta Airlines and this is a long text which should be cutted by CSS',
        },
        {
          link: 'views.client.274008.general',
          title: 'Santorini Cruise',
          description: 'John F Kennedy International Crystal cruise 334737-900',
        },
        {
          link: 'views.client.274008.general',
          title: 'Hilton Hotel',
          description: 'Bern - center, Jaferstrasse 199, ZIP: 18732',
        },
      ],
    },
    {
      title: 'Reports',
      entries: [
        {
          link: 'views.reports.general',
          title: 'Report 293784',
          description: 'Hotel report',
        },
        {
          link: 'views.reports.general',
          title: 'Report +000, /client - ',
          description: 'Client report (Dan Johnson)',
        },
        {
          link: 'views.reports.general',
          title: 'Report 2532',
          description: 'Traveler report',
        },
      ],
    },
    {
      title: 'Proposals',
      entries: [
        {
          link: 'views.client.274008.general',
          title: 'Ramada Berlin',
          description: '4 stars hotel',
        },
        {
          link: 'views.client.274008.general',
          title: 'Amsterdam City break',
          description: 'Rotterdam final destination',
        },
        {
          link: 'views.client.274008.general',
          title: 'Hollywood excursion',
          description: 'All inclusive',
        },
      ],
    },
    {
      title: 'Trips',
      entries: [
        {
          link: 'views.client.274008.general',
          title: 'Trip to Beijing',
          description: 'Travel guide included',
        },
        {
          link: 'views.client.274008.general',
          title: 'Machu Pichu - #46789',
          description:
            '- MXP · ZHK  – John F Kennedy International Airport Boeing 737-900 Passenger Operated by Delta Air Lines · DL488 …',
        },
        {
          link: 'views.client.274008.general',
          title: 'Toronto flight - #53990',
          description: 'Double luggage included, 2 persons.',
        },
      ],
    },
    {
      title: 'Tasks',
      entries: [
        {
          link: 'views.client.274008.general',
          title: 'Task #1 - #35687',
          description: 'No desc',
        },
        {
          link: 'views.client.274008.general',
          title: 'Task #555 - #35687',
          description: 'No desc123123',
        },
        {
          link: 'views.client.274008.general',
          title: 'Task #260 - #35687',
          description: 'No desc123123',
        },
      ],
    },
    {
      title: 'Journeys',
      entries: [
        {
          link: 'views.client.274008.general',
          title: 'Task #1 - #35687',
          description: 'No desc',
        },
        {
          link: 'views.client.274008.general',
          title: 'Task #1 - #35687',
          description: 'No desc123123',
        },
        {
          link: 'views.client.274008.general',
          title: 'Task #1 - #35687',
          description: 'No desc123123',
        },
      ],
    },
    {
      title: 'Fast Pass++',
      entries: [
        {
          link: 'views.client.274008.general',
          title: 'FP++ #1 - #35687',
          description: 'No desc',
        },
        {
          link: 'views.client.274008.general',
          title: 'FP++ #2 - #35687',
          description: 'No desc123123',
        },
        {
          link: 'views.client.274008.general',
          title: 'FP++ #3 - #35687',
          description: 'No desc123123',
        },
      ],
    },
  ];

  static getResults() {
    return JSON.parse(JSON.stringify(this.results));
  }
}
