<div class="search-container" [class.active-search-container]="activeSearch">
  <div class="search-wrapper" [class.search-enabled]="activeSearch">
    <div (click)="closeSearch()" (keypress.enter)="closeSearch()" tabindex="0" class="overlay"></div>
    <div
      (click)="openSearchOnDesktop()"
      (keypress.enter)="openSearchOnDesktop()"
      tabindex="0"
      class="display-flex flex-align-items-center search-bar"
      [class.search-active]="activeSearch">
      <mat-icon class="search-icon" svgIcon="magnify" (click)="openSearch()" />
      <input
        #search_input
        matInput
        (click)="openSearch()"
        (keyup.escape)="closeSearch()"
        class="search-input text-ellipsis"
        type="text"
        placeholder="Search" />
      <!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
      <mat-icon svgIcon="cancel" (click)="clearSearchBox()" class="clear tess-icon-xxs" [style.opacity]="activeSearch ? 1 : 0" />
    </div>
    <div class="search-output" [class.show-output]="activeSearch">
      <div class="results-wrapper">
        <div class="results-meta">
          {{ totalFilteredData.length ? totalFilteredData.length + ' matching results' : 'Type to start searching' }}
        </div>

        <ol #scroll_container class="results-list margin-bottom-0 tess-scrollbar">
          @for (result of paginatedResultData; track result.title) {
            <li class="list-item">
              <div class="article-title">
                <h1 class="pg-title">{{ result.title }}</h1>
              </div>

              @for (item of result.entries; track item.title) {
                <div (click)="navigateToPage(item.link)" (keypress.enter)="navigateToPage(item.link)" tabindex="0" class="entry">
                  <div class="display-flex found-result">
                    <div class="letter-avatar">P</div>
                    <div class="display-flex flex-align-items-center result-text overflow-hidden">
                      <div [innerHTML]="item.title" class="text-ellipsis"></div>
                    </div>
                  </div>
                </div>
              }
            </li>
          }

          <!-- eslint-disable-next-line @angular-eslint/template/no-inline-styles -->
          <li #scroll_anchor class="scroll-anchor" [style.height]="paginatedResultData.length ? '2px' : ''"></li>
        </ol>
      </div>
    </div>
  </div>
</div>

<button type="button" mat-icon-button class="mobile-only" (click)="openSearch()">
  <mat-icon class="search-icon mobile-only" svgIcon="magnify" />
</button>
