@if (authenticatedUserSignal(); as authenticatedUser) {
  <mat-toolbar>
    <div class="tess-greeting">
      <div [tessTooltip]="templateForTooltip">Hi {{ authenticatedUser.data?.Contact?.FirstName }}</div>
      <ng-template #templateForTooltip>
        <strong>This is an example</strong>
        of a tooltip
      </ng-template>
      <mat-icon svgIcon="waving-hand" class="tess-icon-sm" />
    </div>

    <div class="display-flex flex-align-items-center flex-column-gap-24">
      <tess-toolbar-search />
      <div class="display-flex flex-column-gap-4">
        <tess-toolbar-history />
        <tess-toolbar-shortcuts />
        <tess-toolbar-notifications />
      </div>

      <button type="button" mat-button aria-label="Language menu" class="language-button" disableRipple [matMenuTriggerFor]="languageMenu">
        <div class="display-flex flex-align-items-center">
          <span [class]="['language-icon-wrap', currentLanguage]">
            <mat-icon [svgIcon]="'flagkit:' + getLanguage(currentLanguage)" />
          </span>
          <span class="language-text text-uppercase">{{ currentLanguage }}</span>
          <mat-icon svgIcon="arrow-down" class="icon-tess-gray-stroke tess-icon-sm" />
        </div>
      </button>
      <mat-menu class="tess-menu-panel" #languageMenu="matMenu">
        @for (language of languages; track language) {
          <button type="button" mat-menu-item (click)="onLanguageChange(language)">
            <span [class]="['language-icon-wrap', language]">
              <mat-icon [svgIcon]="'flagkit:' + getLanguage(language)" />
            </span>
            <span class="text-uppercase">{{ language }}</span>
          </button>
        }
      </mat-menu>

      <button
        type="button"
        mat-button
        aria-label="Agent menu"
        class="agent-menu-trigger margin-right-8"
        disableRipple
        [matMenuTriggerFor]="menu">
        <div class="display-flex flex-align-items-center">
          <tess-avatar
            [src]="authenticatedUser.data?.Contact?.PhotoURL"
            [initials]="authenticatedUser.data?.Contact?.Initials"
            [title]="authenticatedUser.data?.Contact?.FullName"
            size="xs" />
          <span class="contact-name">{{ authenticatedUser.data?.Contact?.FirstName }} {{ authenticatedUser.data?.Contact?.LastName }}</span>
          <mat-icon svgIcon="arrow-down" class="icon-tess-gray-stroke tess-icon-sm" />
        </div>
      </button>
      <mat-menu #menu="matMenu" class="tess-menu-panel">
        <button type="button" mat-menu-item (click)="navigateToMyProfile()" aria-label="My profile">
          <mat-icon svgIcon="my-account" class="icon-tess-gray-stroke" />
          <span>My profile</span>
        </button>
        <button mat-menu-item (click)="navigateToMyCompany()" aria-label="My company">
          <mat-icon svgIcon="my-company" class="icon-tess-gray-stroke" />
          <span>My company</span>
        </button>
        <mat-divider />
        <button type="button" mat-menu-item (click)="logOut()" aria-label="Log Out">
          <mat-icon svgIcon="logout" class="icon-tess-gray-stroke" />
          <span>Log out</span>
        </button>
      </mat-menu>
    </div>
  </mat-toolbar>
}
