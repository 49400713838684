import { Component, input, output } from '@angular/core';

import { MatIconModule } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'tess-navigation-popover',
  templateUrl: './navigation-popover.component.html',
  styleUrls: ['./navigation-popover.component.scss'],
  imports: [MatIconModule],
  host: {
    '[class]': 'position()',
  },
})
export class NavigationPopoverComponent {
  protected readonly item = input.required<any>();
  protected readonly position = input<string | undefined>(undefined);

  readonly navigate = output<any>();
}
