import { ChangeDetectionStrategy, Component, inject, InjectionToken, type TemplateRef } from '@angular/core';
import { NgTemplateOutlet } from '@angular/common';

export interface TooltipData {
  focus: () => void;
  blur: () => void;
  template: TemplateRef<void>;
}
export const TOOLTIP_DATA = new InjectionToken<TooltipData>('Tooltip data');

@Component({
  selector: 'tess-tooltip',
  standalone: true,
  imports: [NgTemplateOutlet],
  templateUrl: './tooltip.component.html',
  styleUrl: './tooltip.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '(mouseenter)': 'focus()',
    '(mouseleave)': 'blur()',
  },
})
export class TooltipComponent {
  data = inject<TooltipData>(TOOLTIP_DATA);

  focus() {
    this.data.focus();
  }

  blur() {
    this.data.blur();
  }
}
