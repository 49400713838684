@let brand = branding();

<div class="navigation-top" [class.navigation-top--closed]="closed()">
  <div class="menu">
    <div class="menu__left">
      <img [src]="brand.LogoLight" [attr.alt]="brand.Name" [attr.title]="brand.Name" />
    </div>

    <div class="menu__right">
      <div class="navigation-top__logo">
        <img [src]="brand.ApplicationNameCompanyNameLight" [attr.alt]="brand.Name" [attr.title]="brand.Name" />
      </div>
    </div>
  </div>

  <button class="navigation-top__actions" (click)="toggle.emit()">
    <mat-icon svgIcon="arrow-backward" class="tess-icon-md"></mat-icon>
  </button>
</div>
