import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { Router } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

@Component({
  standalone: true,
  selector: 'tess-toolbar-history',
  templateUrl: './toolbar-history.component.html',
  styleUrls: ['./toolbar-history.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [MatButtonModule, MatIconModule, MatMenuModule],
})
export class ToolbarHistoryComponent {
  readonly #router = inject(Router);

  options = [
    { title: 'Clients', routeName: '/clients' },
    { title: 'Calendar', routeName: '/calendar' },
    { title: 'Referral Groups', routeName: '/referral-groups/clients' },
    { title: 'Reports', routeName: '/reports' },
    { title: 'Tickets', routeName: '/helpdesks' },
    { title: 'Bookings', routeName: '/bookings' },
  ];

  navigate(link: string): void {
    this.#router.navigate([link]);
  }
}
