<button type="button" mat-icon-button class="bell-button" [matMenuTriggerFor]="menu">
  <mat-icon class="cursor-pointer bell-icon tess-icon-sm" svgIcon="bell" />

  @if (notifications().length) {
    <span class="notification-count">{{ notifications().length }}</span>
  }
</button>
<mat-menu #menu="matMenu" class="toolbar-menu">
  @if (notifications().length) {
    <div>
      <div class="no-new-notifications">
        <span>{{ notifications().length }} notifications</span>
      </div>

      @for (notification of notifications(); track notification.messageId) {
        <div class="quick-link-option">
          <div class="image-holder"></div>
          <div>
            <div class="option-title">{{ notification.notification.title }}</div>
            <div>{{ notification.notification.body }}</div>
          </div>
        </div>
      }
    </div>
  } @else {
    <div class="no-new-notifications">
      <span>No new notifications</span>
    </div>
    <div class="image-placeholder">No new notifications</div>
  }
</mat-menu>

<!-- <div fxLayout="column" *ngIf="!data?.CountFiltered()">
    <div class="no-new-notifications" fxLayout="row" fxLayoutAlign="center center">
      <span>No new notifications</span>
    </div>
    <div class="image-placeholder" fxLayoutAlign="center center">
      <img src="images/notifications.png" alt="No new notifications" />
    </div>
  </div>

  <div fxLayout="column" *ngIf="data?.CountFiltered()">
    <div class="no-new-notifications" fxLayout="row" fxLayoutAlign="center center">
      <span>{{ data.CountFiltered() }} notifications</span>
    </div>
    <div
      *ngFor="let message of data.Items"
      (click)="openMessage(message)"
      class="quick-link-option"
      fxLayout="row"
      fxLayoutAlign="start center"
      fxLayoutGap="10px"
    >
      <div class="image-holder">
        <img [src]="message.SenderPhoto || 'images/travelesolutions/user.png'" alt="" />
      </div>
      <div fxLayout="column">
        <div class="option-title">{{ message.Sender.Name }}</div>
        <div>{{ message.Title }}</div>
      </div>
    </div>
    <a (click)="viewAll()" class="notification-footer" fxLayoutAlign="center center">
      <a>View All</a>
    </a>
  </div>
</mat-menu> -->
