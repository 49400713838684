<div class="menu">
  <div class="menu__left"></div>
  <div class="menu__right">
    <div class="actions">
      <div class="actions-item">
        <mat-icon svgIcon="my-tasks" class="menu-icon tess-icon-md"></mat-icon>
        <div class="margin-top-4">Tasks</div>
      </div>
      <div class="actions-item">
        <mat-icon svgIcon="events" class="menu-icon tess-icon-md"></mat-icon>
        <div class="margin-top-4">Events</div>
      </div>
      <div class="actions-item">
        <mat-icon svgIcon="payments-due" class="menu-icon tess-icon-md"></mat-icon>
        <div class="margin-top-4">Payments due</div>
      </div>
    </div>
  </div>
</div>
