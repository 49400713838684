import { DefaultUrlSerializer, UrlTree } from '@angular/router';

const tessRouteMappings: Record<string, string> = {
  '/clients/detail:client/': '/client/',
  '/trips/detail:trip/': '/trip/',
  '/reservations//subdetail:reservation/': '/reservation/',
  '/bookings//yyy:booking/': '/booking/',
  '/bookings//subdetail:booking/': '/booking/',
};

export class TessUrlSerializer extends DefaultUrlSerializer {
  parse(url: string): UrlTree {
    const routeUrl = url
      .replace(
        new RegExp(Object.values(tessRouteMappings).join('|'), 'g'),
        match => Object.entries(tessRouteMappings).find(([_key, value]) => value === match)[0],
      )
      .replace(/(\/[^/]+:.+)/gm, match => `/(${match.slice(1)})`);
    return super.parse(routeUrl);
  }

  serialize(tree: UrlTree): string {
    const routeUrl = super.serialize(tree);
    return routeUrl
      .replace('(', '')
      .replace(')', '')
      .replace(new RegExp(Object.keys(tessRouteMappings).join('|'), 'g'), match => tessRouteMappings[match]);
  }
}
