import {
  APP_INITIALIZER,
  ApplicationConfig,
  ENVIRONMENT_INITIALIZER,
  importProvidersFrom,
  inject,
} from '@angular/core';
import { getMessaging, provideMessaging } from '@angular/fire/messaging';
import { HttpClient, provideHttpClient, withInterceptors } from '@angular/common/http';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, UrlSerializer } from '@angular/router';

import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SORT_HEADER_INTL_PROVIDER } from '@angular/material/sort';

import { lastValueFrom } from 'rxjs';

import { environment } from 'src/environments/environment';
import { provideNgxMask } from 'ngx-mask';

import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { authInterceptor } from 'src/app/http-auth.interceptor';
import { AuthService } from 'src/app/auth.service';
import { ConfigurationService } from 'src/app/configuration.service';
import { IconRepositoryService } from 'src/app/icon-repository.service';
import { routes } from 'src/app/app.routes';
import { TessUrlSerializer } from 'src/app/tess-url-serializer';

import { AuthStore } from '@core/auth/models/auth-store';

function appInit(configurationService: ConfigurationService, authService: AuthService): () => Promise<AuthStore> {
  return async () => {
    // Load the configuration.
    await configurationService.load();
    // Refresh the token.
    return lastValueFrom(authService.refreshToken());
  };
}

function delayInitialLoading(delay = 100): () => Promise<void> {
  return (): Promise<void> => new Promise<void>(resolve => setTimeout(resolve, delay));
}

// AoT requires an exported function for factories
function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    provideHttpClient(withInterceptors([authInterceptor])),
    {
      provide: UrlSerializer,
      useClass: TessUrlSerializer,
    },
    {
      provide: APP_INITIALIZER,
      deps: [ConfigurationService, AuthService],
      multi: true,
      useFactory: appInit,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: delayInitialLoading,
    },
    {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue: () => inject(IconRepositoryService),
    },
    MAT_SORT_HEADER_INTL_PROVIDER,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { disableClose: true } },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: { appearance: 'fill', floatLabel: 'always' },
    },
    importProvidersFrom(
      // Translations.
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
    ),
    provideNgxMask(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideMessaging(() => getMessaging()),
  ],
};
