import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { getToken, Messaging, onMessage } from '@angular/fire/messaging';
import { HttpClient } from '@angular/common/http';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

import { catchError, filter, from, map, merge, Observable, of, pairwise, startWith, switchMap, take } from 'rxjs';

import { MessagePayload } from '@firebase/messaging';

import { AuthService } from './auth.service';
import { ConfigurationService } from './configuration.service';

/**
 * firebase js sdk hack
 * fix an error "AbortError: Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker"
 * @see https://github.com/firebase/firebase-js-sdk/issues/7575
 */
void registerFirebaseSw();

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  readonly #destroyRef = inject(DestroyRef);
  readonly #configurationService = inject(ConfigurationService);
  readonly #authService = inject(AuthService);
  readonly #http = inject(HttpClient);
  readonly messaging = inject(Messaging);

  readonly #BASE_API_NOTIFICATIONS = `${this.#configurationService.settings.ApiServiceBaseUri}api/PushNotification`;

  readonly notifications = signal<MessagePayload[]>([]);

  constructor() {
    toObservable(this.#authService.authenticatedUserSignal)
      .pipe(
        startWith(null),
        pairwise(),
        switchMap(([prevUser, user]) => {
          if (!user?.data) {
            if (prevUser?.data) {
              this.#unsubscribeNotifications(prevUser.data.Id);
            }

            return of(false);
          }

          return this.#requestPushNotifications();
        }),
        switchMap(granted => {
          if (!granted) {
            return of(null);
          }

          return merge(this.#activePageNotifications(), this.#backgroundNotifications());
        }),
        takeUntilDestroyed(),
      )
      .subscribe(notification => {
        if (notification != null) {
          this.notifications.update(notifications => {
            return [notification, ...notifications];
          });
        }
      });
  }

  sendPushNotification(userIds: number[], subject: string, message: string): Observable<void> {
    return this.#http.post<void>(`${this.#BASE_API_NOTIFICATIONS}/send-push-notification`, {
      UserIds: userIds,
      Subject: subject,
      Message: message,
    });
  }

  #requestPushNotifications(): Observable<boolean> {
    return from(registerFirebaseSw()).pipe(
      switchMap(serviceWorkerRegistration => getToken(this.messaging, { serviceWorkerRegistration })),
      catchError((error): Observable<false> => {
        // eslint-disable-next-line no-console
        console.warn(error);

        return of(false);
      }),
      switchMap(token => {
        if (!token) {
          return of(false);
        }

        return this.#subscribeToPushNotifications(token).pipe(map(() => true));
      }),
      catchError(() => of(false)),
    );
  }

  #unsubscribeNotifications(userId: number): void {
    from(getToken(this.messaging))
      .pipe(
        catchError(() => of(null)),
        filter(i => i != null),
        switchMap(token => this.#unsubscribeFromPushNotifications(userId, token)),
        take(1),
        takeUntilDestroyed(this.#destroyRef),
      )
      .subscribe(() => {
        this.notifications.set([]);
      });
  }

  #subscribeToPushNotifications(registrationToken: string): Observable<void> {
    return this.#http.get<void>(`${this.#BASE_API_NOTIFICATIONS}/subscribe-to-push-notifications`, {
      params: { registrationToken },
    });
  }

  #unsubscribeFromPushNotifications(userId: number, registrationToken: string): Observable<void> {
    return this.#http.get<void>(`${this.#BASE_API_NOTIFICATIONS}/unsubscribe-from-push-notifications`, {
      params: { userId, registrationToken },
    });
  }

  #activePageNotifications(): Observable<MessagePayload> {
    return new Observable<MessagePayload>(sub => {
      return onMessage(this.messaging, msg => sub.next(msg));
    });
  }

  #backgroundNotifications(): Observable<MessagePayload> {
    const channel = new window.BroadcastChannel('bg-notification');

    return new Observable(subscriber => {
      const handler = (payload: MessageEvent<MessagePayload>) => {
        subscriber.next(payload.data);
      };

      channel.addEventListener('message', handler);

      return () => {
        channel.removeEventListener('message', handler);
      };
    });
  }
}

function registerFirebaseSw(): Promise<ServiceWorkerRegistration> {
  return navigator.serviceWorker.register('/assets/firebase-messaging-sw.js', {
    type: 'module',
  });
}
